import { CreateAuctionForm } from 'widgets/Auctions/CreateAuctionForm';
import { UploadModal } from 'widgets/UploadModal';
import { FilesList } from 'widgets/FilesList';
import { useLocation } from 'react-router-dom';
import { useAuction } from 'shared/hooks/useAuction';

import { Button } from '../../shared/ui/Button';
import styles from './ChangeAuction.module.scss';

const ChangeAuction = () => {
  const { state } = useLocation();

  const { id, initialBet, description, endDate, startDate, name, images, fullRedemption } = state;

  const {
    handleChangeAuction,
    handleChangeForm,
    handleClickIsVisibleUploadModal,
    handleSetFiles,
    isVisibleUploadModal,
    photos,
    setPhotos,
    setIsVisibleUploadModal,
    form,
  } = useAuction(name, startDate, endDate, description, initialBet, images, fullRedemption, id);

  return (
    <div>
      <CreateAuctionForm isChangePage form={form} handleChangeForm={handleChangeForm} />
      <FilesList
        setFiles={setPhotos}
        files={photos}
        handleClick={handleClickIsVisibleUploadModal}
      />
      <Button onClick={handleChangeAuction} additionalClasses={styles.button} type={3}>
        Опубликовать
      </Button>
      <UploadModal
        isVisible={isVisibleUploadModal}
        close={() => setIsVisibleUploadModal(false)}
        title={'Добавить новое фото'}
        dropzoneText={'Загрузите фото с устройства или перетащите файлы в это поле'}
        handleSetFiles={handleSetFiles}
      />
    </div>
  );
};

export default ChangeAuction;
