import { useState } from 'react';
import { Button } from 'shared/ui/Button';
import { Badge } from 'shared/ui/Badge';
// import { addCatalogToFavorites, deleteCatalogFromFavorites } from 'entities/catalogs/api';
// import { useAppDispatch } from 'app/store';
import { Catalog } from 'entities/catalogs/types';
// import { BookMark } from 'shared/ui/BookMark';
// eslint-disable-next-line import/order
import { ReactComponent as EditSVG } from 'shared/assets/icons/edit.svg';
// import routes from 'shared/constans/routes';
// import { useToast } from 'shared/hooks/useToast/useToast';
// import { ToastVariants } from 'shared/context/ToastContext/interfaces';
// import { PaymentModalWidget } from 'widgets/User/PaymentModalWidget';
// import { CatalogAccessWidgetModal } from 'widgets/Catalogs/CatalogAccessWidgetModal';

import { IMAGES_URL } from 'shared/config/urls';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'shared/constans/routes';

import styles from './CatalogItem.module.scss';

export const CatalogItem = ({
  id,
  isFavorite,
  isBuyed,
  price,
  title,
  subtitle,
  questionnaires,
  countQuestionnaires,
  classname,
  index = 0,
  type,
  lastUpdate,
}: Catalog) => {
  // const { t } = useTranslation('catalog');
  // const { locale = 'ru' } = useRouter();
  // const router = useRouter();
  // const dispatch = useAppDispatch();
  // const { showToast } = useToast();
  const navigate = useNavigate();

  const BASE_ANIMATION_DELAY = 0.2;
  const animationDelay = index * BASE_ANIMATION_DELAY;

  const getQuantityQuestionnaires = () => {
    if (countQuestionnaires < 100) {
      return countQuestionnaires;
    }

    return Math.floor(countQuestionnaires / 100) * 100;
  };

  const [isLocalFavorite, setIsLocalFavorite] = useState(isFavorite);
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  // console.log(isVisibleModal);

  const handleClickFavoriteButton = (id: number) => {
    setIsLocalFavorite((prev) => !prev);
    if (isLocalFavorite) {
      // dispatch(deleteCatalogFromFavorites(id));
      // showToast({
      //   message: `${t('toasts.catalog')} ${title[locale]} ${t('toasts.deleted')}`,
      //   variant: ToastVariants.SUCCESS,
      // });
    } else {
      // dispatch(addCatalogToFavorites(id));
      // showToast({
      //   message: `${t('toasts.catalog')} ${title[locale]} ${t('toasts.added')}`,
      //   variant: ToastVariants.SUCCESS,
      // });
    }
  };

  const handleClickBuyOrOpenButton = () => {
    // isBuyed ? router.push('/catalogs/[id]', `${routes.CATALOGS}/${id}`) : setIsVisibleModal(true);
  };

  // console.log(handleClickBuyOrOpenButton);
  if (type === 'subscription') {
    return (
      <div
        style={{ animationDelay: `${animationDelay}s` }}
        className={`${styles.item} ${styles.subscription} ${styles[classname]}`}
        key={id}
      >
        <div className={styles.header_content}>
          <Badge
            additionalClasses={styles.last_update}
            value={`Обновлен ${new Date(lastUpdate).toLocaleDateString()}`}
          />
        </div>
        <div className={styles.text_container}>
          <p className={styles.title}>{title['ru']}</p>
          <p className={styles.subtitle}>{subtitle['ru']}</p>
        </div>
        <div
          className={`${styles.bottom_content} ${
            questionnaires.length ? 'justify-space-between' : 'justify-end'
          }`}
        >
          {questionnaires.length ? (
            <div className={styles.images}>
              {questionnaires.map(({ id, avatars }) => (
                <img
                  className={styles.image}
                  width={40}
                  height={40}
                  key={id}
                  src={`${IMAGES_URL}${avatars[0]?.file?.preview}`}
                  alt={'girl'}
                />
              ))}
              <Badge
                additionalClasses={styles.badge}
                value={getQuantityQuestionnaires()}
                type={classname}
              />
            </div>
          ) : null}
        </div>
        <Button additionalClasses={`${styles.button}`} type={isBuyed ? 4 : classname}>
          {isBuyed ? 'Открыть' : `Купить ${price}€`}
        </Button>
        {/* <PaymentModalWidget isVisible={isVisibleModal} close={() => setIsVisibleModal(false)} /> */}
      </div>
    );
  }
  return (
    <div
      style={{ animationDelay: `${animationDelay}s` }}
      className={`${styles.item} ${styles[classname]}`}
      key={id}
    >
      <div className={styles.header_content}>
        <Badge
          additionalClasses={styles.last_update}
          value={`Обновлен ${new Date(lastUpdate).toLocaleDateString()}`}
        />
        {/* {isLocalFavorite !== undefined && ( */}
        <Button
          // onClick={() => handleClickFavoriteButton(id)}
          onClick={() => navigate(`${ROUTES.EDIT_CATALOG}/${id}`)}
          additionalClasses={styles.icon_button}
          type={4}
        >
          {/* <BookMark checked={isLocalFavorite} /> */}
          <EditSVG />
        </Button>
        {/* )} */}
      </div>
      <div className={styles.text_container}>
        <p className={styles.title}>{title['ru']}</p>
        <p className={styles.subtitle}>{subtitle['ru']}</p>
      </div>
      <div
        className={`${styles.bottom_content} ${
          questionnaires.length ? 'justify-space-between' : 'justify-end'
        }`}
      >
        {questionnaires.length ? (
          <div className={styles.images}>
            {questionnaires.map(({ id, avatars }) => (
              <img
                className={styles.image}
                width={40}
                height={40}
                key={id}
                src={`${IMAGES_URL}${avatars[0]?.file?.preview}`}
                alt={'girl'}
              />
            ))}
            <Badge
              additionalClasses={styles.badge}
              value={getQuantityQuestionnaires()}
              type={classname}
            />
          </div>
        ) : null}
        <Button
          // onClick={handleClickBuyOrOpenButton}
          additionalClasses={`${styles.button}`}
          type={isBuyed ? 4 : classname}
        >
          {/* {isBuyed ? 'Открыть' : `купить ${price}€`} */}
          {`Купить ${price}$`}
        </Button>
      </div>
      {/* <CatalogAccessWidgetModal
        title={title[locale]}
        isVisible={isVisibleModal}
        close={() => setIsVisibleModal(false)}
        className={classname}
      /> */}
    </div>
  );
};
