import { Button } from 'shared/ui/Button';
import { createNumberedArray } from 'shared/helpers/createNumberedArray';
import { IMAGES_URL } from 'shared/config/urls';

import styles from './FilesList.module.scss';
import { ReactComponent as AddIcon } from '../../shared/assets/icons/addsquare.svg';
import { ReactComponent as TrashIcon } from '../../shared/assets/icons/trash.svg';
import { FilesListPropsI } from './FilesList.props';
import { FileType } from '../../shared/types/FileType';
import { instance } from '../../shared/config/api/api';

export const FilesList = ({
  handleClick,
  files,
  setFiles,
  isCreateForm = false,
}: FilesListPropsI) => {
  const difference = 3 - files.length;
  const handleDeleteFiles = (file: FileType) => {
    setFiles((prev) => prev.filter(({ id }) => id !== file.id));
    if (isCreateForm) {
      instance
        .post('/delete-files', { files: [file] })
        .catch((error) => console.error('Ошибка при удалении файла', error));
    }
  };

  return (
    <ul className={styles.list_upload_button}>
      <li key={1} className={styles.item}>
        <Button onClick={handleClick} type={6}>
          <AddIcon />
          Добавить фото
        </Button>
      </li>
      {files.map((file) => (
        <li className={styles.image_item} key={file.id}>
          <img className={styles.image} src={`${IMAGES_URL}${file.full || ''}`} alt="preview" />
          <button onClick={() => handleDeleteFiles(file)} className={styles.trash_button}>
            {<TrashIcon />}
          </button>
        </li>
      ))}
      {difference > 0
        ? createNumberedArray(difference).map((_, index) => (
            <li key={index} className={styles.empty_photo}></li>
          ))
        : null}
    </ul>
  );
};
