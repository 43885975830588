import styles from './FileList.module.scss';
import { FileListPropsI } from './FileList.props';
import { PhotoListItem } from './PhotoListItem';

export const FileList = ({ files, handleRemove }: FileListPropsI) => {
  return files.length ? (
    <ul className={styles.list}>
      {files.map((file) =>
        file.type === 'image' ? (
          <PhotoListItem
            key={file.id || file.correlationId}
            file={file}
            handleRemove={handleRemove}
          />
        ) : (
          <PhotoListItem
            key={file.id || file.correlationId}
            file={file}
            handleRemove={handleRemove}
          />
        )
      )}
    </ul>
  ) : null;
};
