import { useCallback, useRef } from 'react';
import { ReactComponent as AddSquareIcon } from 'shared/assets/icons/addsquare.svg';
import { ReactComponent as MinusSquareIcon } from 'shared/assets/icons/minussquare.svg';
import classNames from 'classnames';

import styles from './Input.module.scss';
import { InputPropsType } from './InputPropsType';

export const Input = ({
  placeholder = '',
  value,
  setValue,
  type,
  additionalClasses = '',
  iconRight = null,
  status = '',
  tip,
  valueType,
  numberStep,
  minNum,
  maxNum,
  tipAsPlaceholder,
  showStepButtons,
  multilines,
  isError,
  pattern,
  inputmode,
}: InputPropsType) => {
  const tipRef = useRef<HTMLElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const onFocus = useCallback(() => {
    if (
      tip &&
      tipAsPlaceholder &&
      tipRef.current &&
      tipRef.current.classList.contains(styles['tip-placeholder'])
    ) {
      tipRef.current.classList.remove(styles['tip-placeholder']);
    }
  }, [value]);

  const add = useCallback(() => {
    if (setValue) {
      if (value && maxNum && +value < maxNum) {
        const val = +value + 1;
        setValue(val.toString());
      } else if (!value) {
        const val = minNum ? minNum.toString() : '1';
        setValue(val);
      }
    }
  }, [value]);
  const minus = useCallback(() => {
    if (setValue) {
      if (value && minNum && +value > minNum) {
        const val = +value - 1;
        setValue(val.toString());
      } else if (!value) {
        const val = minNum ? minNum.toString() : '1';
        setValue(val);
      }
    }
  }, [value]);
  const onBlur = useCallback(
    (e: any) => {
      if (setValue && maxNum && e.target.value && +e.target.value > maxNum) {
        setValue(maxNum.toString());
      } else if (setValue && minNum && e.target.value && +e.target.value < minNum) {
        setValue(minNum.toString());
      }
      if (
        tip &&
        tipAsPlaceholder &&
        tipRef.current &&
        !value &&
        !tipRef.current.classList.contains(styles['tip-placeholder'])
      ) {
        tipRef.current.classList.add(styles['tip-placeholder']);
      }
    },
    [value]
  );

  const input = multilines ? (
    <textarea
      rows={7}
      className={`${styles.input} `}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        setValue && setValue(e.target.value);
        e.currentTarget.style.height = '124px';
        e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
      }}
      onFocus={onFocus}
      onBlur={onBlur}
    ></textarea>
  ) : (
    <input
      ref={inputRef}
      className={`${styles.input}`}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        setValue && setValue(e.target.value);
      }}
      type={valueType || 'text'}
      step={numberStep}
      pattern={pattern}
      inputMode={inputmode}
      min={minNum}
      max={maxNum}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
  return (
    <div
      className={classNames(
        styles.container,
        multilines && styles.multilines,
        styles['input-container' + type],
        additionalClasses && additionalClasses,
        status && styles[status],
        isError && styles.error
      )}
    >
      {input}
      {iconRight && <div className={styles['icon-right-container']}>{iconRight}</div>}
      {showStepButtons && (
        <div className={styles['step-buttons-container']}>
          <MinusSquareIcon className="svg_stroke" onClick={minus} />
          <AddSquareIcon className="svg_stroke" onClick={add} />
        </div>
      )}
      {tip && (
        <span
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
          ref={tipRef}
          className={`${styles.tip} ${
            tipAsPlaceholder &&
            !value &&
            (typeof document === 'undefined' || document.activeElement !== inputRef.current)
              ? styles['tip-placeholder']
              : ''
          }`}
        >
          {tip}
        </span>
      )}
    </div>
  );
};
