import { useEffect, useRef, useState, useCallback } from 'react';
import { useAppStore, useAppDispatch, useAppSelector } from 'app/store';
import { FormType } from 'shared/types/FormType/FormType';
import { ToastVariants } from 'shared/context/ToastContext/interfaces';

import { useToast } from '../useToast';
import { instance } from '../../config/api/api';
import { QuestionnaireContent, QuestionnaireDetails } from '../../../entities/questionnaires/types';
import {
  fetchFormats,
  fetchTypes,
  getCatalogs,
  getCities,
  getCountries,
  createQuestionnaire,
  updateQuestionnaire,
} from '../../../entities/questionnaires/api';
import {
  clearQuestionnaireContents,
  clearQuestionnaireErrors,
} from '../../../entities/questionnaires/models';

export const useQuestionnaire = ({
  questionnaireDetails = {},
  isUpdateMode = false,
}: {
  questionnaireDetails?: Partial<QuestionnaireDetails>;
  isUpdateMode?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const {
    countries,
    cities,
    catalogNames,
    types,
    formats,
    errors,
    isVisibleUploadModal,
    isVisibleAvatarUploadModal,
  } = useAppSelector((store) => store.questionnaires);

  const [selects, setSelects] = useState(() => {
    return {
      countryId: questionnaireDetails.city?.country.id || 0,
      cityId: questionnaireDetails.city?.id || 0,
      catalogs: questionnaireDetails.catalogs?.[0]?.id || 0,
      types: questionnaireDetails.types?.[0]?.id || 0,
      formats: questionnaireDetails.formats?.[0]?.id || 0,
    };
  });

  const [form, setForm] = useState(() => createInitialForm(questionnaireDetails, selects));
  const [photos, setPhotos] = useState<QuestionnaireContent[] | null>(null);
  const [videos, setVideos] = useState<QuestionnaireContent[] | null>(null);
  const [hots, setHots] = useState<QuestionnaireContent[] | null>(null);
  const [avatars, setAvatars] = useState<QuestionnaireContent[] | null>(null);
  const [phone, setPhone] = useState<string>(questionnaireDetails.phone || '');

  let isCreatedOrChanged = useRef(false);

  const initialPhotos = useRef<QuestionnaireContent[]>();
  const newPhotos = useRef<QuestionnaireContent[]>([]);

  const initialVideos = useRef<QuestionnaireContent[]>();
  const newVideos = useRef<QuestionnaireContent[]>([]);

  const initialHots = useRef<QuestionnaireContent[]>();
  const newHots = useRef<QuestionnaireContent[]>([]);

  const initialAvatars = useRef<QuestionnaireContent[]>();
  const newAvatars = useRef<QuestionnaireContent[]>([]);

  const [wasOpenedUploadModal, setWasOpenedUploadModal] = useState(false);

  useEffect(() => {
    if (isVisibleUploadModal || isVisibleAvatarUploadModal) {
      setWasOpenedUploadModal(true);
    }
  }, [isVisibleUploadModal, isVisibleAvatarUploadModal]);

  useEffect(() => {
    if (photos !== null && !wasOpenedUploadModal) {
      initialPhotos.current = photos;
    }
  }, [photos, wasOpenedUploadModal]);

  useEffect(() => {
    if (videos !== null && !wasOpenedUploadModal) {
      initialVideos.current = videos;
    }
  }, [videos, wasOpenedUploadModal]);

  useEffect(() => {
    if (hots !== null && !wasOpenedUploadModal) {
      initialHots.current = hots;
    }
  }, [hots, wasOpenedUploadModal]);

  useEffect(() => {
    if (avatars !== null && !wasOpenedUploadModal) {
      initialAvatars.current = avatars;
    }
  }, [avatars, wasOpenedUploadModal]);

  useEffect(() => {
    if (questionnaireDetails.phone) {
      setPhone(questionnaireDetails.phone);
    }
  }, [questionnaireDetails.phone]);

  const handleChangeForm = useCallback((fieldName: string, value?: string | number) => {
    setForm((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        value: value,
      },
    }));
  }, []);

  useEffect(() => {
    setForm((prevForm) => {
      const updatedForm = { ...prevForm };
      Object.keys(prevForm).forEach((fieldName) => {
        updatedForm[fieldName] = {
          ...prevForm[fieldName],
          errorText: errors?.[fieldName] || '',
          isValid: !errors || !errors[fieldName],
        };
      });
      return updatedForm;
    });
  }, [errors]);

  const { showToast } = useToast();

  useEffect(() => {
    dispatch(getCatalogs());
    dispatch(getCountries());
    dispatch(fetchTypes());
    dispatch(fetchFormats());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCities({ countryId: selects.countryId }));
  }, [dispatch, selects.countryId]);

  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      countryId: { ...prevForm.countryId, currentOption: selects.countryId },
      cityId: { ...prevForm.cityId, currentOption: selects.cityId },
      catalogs: { ...prevForm.catalogs, currentOption: selects.catalogs },
      types: { ...prevForm.types, currentOption: selects.types },
      formats: { ...prevForm.formats, currentOption: selects.formats },
    }));
  }, [selects, setForm]);

  useEffect(() => {
    if (Object.keys(questionnaireDetails).length > 0) {
      const updatedSelects = {
        countryId: questionnaireDetails.city?.country.id || 0,
        cityId: questionnaireDetails.city?.id || 0,
        catalogs: questionnaireDetails.catalogs?.[0]?.id || 0,
        types: questionnaireDetails.types?.[0]?.id || 0,
        formats: questionnaireDetails.formats?.[0]?.id || 0,
      };
      setSelects(updatedSelects);
      setForm(createInitialForm(questionnaireDetails, updatedSelects));
    }
  }, [questionnaireDetails]);

  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      countryId: {
        ...prevForm.countryId,
        currentOption: selects.countryId,
        selectOptions: countries.map((c) => ({ id: c.id, value: c.name.ru })),
      },
      cityId: {
        ...prevForm.cityId,
        currentOption: selects.cityId,
        selectOptions: cities.map((c) => ({ id: c.id, value: c.name.ru })),
      },
      catalogs: {
        ...prevForm.catalogs,
        currentOption: selects.catalogs,
        selectOptions: catalogNames.map((c) => ({ id: c.id, value: c.title.ru })),
      },
      types: {
        ...prevForm.types,
        currentOption: selects.types,
        selectOptions: types.map((t) => ({ id: t.id, value: t.name.ru })),
      },
      formats: {
        ...prevForm.formats,
        currentOption: selects.formats,
        selectOptions: formats.map((f) => ({ id: f.id, value: f.name.ru })),
      },
    }));
  }, [selects, countries, cities, catalogNames, types, formats]);

  const handleCountryChange = (id: number) => {
    setSelects((prev) => ({ ...prev, countryId: id, cityId: 0 }));
  };

  const handleCityChange = (id: number) => {
    const currentCities = store.getState().questionnaires.cities;
    const selectedCity = currentCities.find((city) => city.id === id);
    if (selectedCity) {
      setSelects((prev) => ({
        ...prev,
        cityId: selectedCity.id,
        countryId: selectedCity.country.id,
      }));
    }
  };

  const handleCatalogsChange = (id: number) => {
    setSelects((prev) => ({ ...prev, catalogs: id }));
  };

  const handleTypesChange = (id: number) => {
    setSelects((prev) => ({ ...prev, types: id }));
  };

  const handleFormatsChange = (id: number) => {
    setSelects((prev) => ({ ...prev, formats: id }));
  };

  useEffect(() => {
    const initialPhotosFull = new Set(initialPhotos.current?.map((file) => file.file.full));
    const newPhotosFull = new Set(newPhotos.current.map((file) => file.file.full));

    photos?.forEach((photo) => {
      if (!initialPhotosFull.has(photo.file.full) && !newPhotosFull.has(photo.file.full)) {
        newPhotos.current.push(photo);
      }
    });
  }, [photos]);

  useEffect(() => {
    const initialVideosCorrelation = new Set(
      initialVideos.current?.map((file) => file.file.correlationId)
    );
    const newVideosCorrelation = new Set(newVideos.current.map((file) => file.file.correlationId));

    videos?.forEach((video) => {
      if (
        !initialVideosCorrelation.has(video.file.correlationId) &&
        !newVideosCorrelation.has(video.file.correlationId)
      ) {
        newVideos.current.push(video);
      }
    });
  }, [videos]);

  useEffect(() => {
    const initialHotsIdentifier = new Set(
      initialHots.current?.map((file) => file.file.correlationId || file.file.full)
    );
    const newHotsIdentifier = new Set(
      newHots.current.map((file) => file.file.correlationId || file.file.full)
    );

    hots?.forEach((hot) => {
      if (
        !initialHotsIdentifier.has(hot.file.correlationId) &&
        !initialHotsIdentifier.has(hot.file.full) &&
        !newHotsIdentifier.has(hot.file.correlationId) &&
        !newHotsIdentifier.has(hot.file.full)
      ) {
        newHots.current.push(hot);
      }
    });
  }, [hots]);

  useEffect(() => {
    const initialAvatarsFull = new Set(initialAvatars.current?.map((file) => file.file.full));
    const newAvatarsFull = new Set(newAvatars.current.map((file) => file.file.full));

    avatars?.forEach((avatar) => {
      if (!initialAvatarsFull.has(avatar.file.full) && !newAvatarsFull.has(avatar.file.full)) {
        newAvatars.current.push(avatar);
      }
    });
  }, [avatars]);

  const cleanupFiles = () => {
    dispatch(clearQuestionnaireContents());
    dispatch(clearQuestionnaireErrors());
    if (!isCreatedOrChanged.current) {
      if (newPhotos.current.length > 0) {
        instance
          .post('/delete-files', { files: newPhotos.current.map((item) => ({ ...item.file })) })
          .catch((error) => console.error('Error deleting image', error));
      }
      if (newVideos.current.length > 0) {
        instance
          .post('/delete-files', { files: newVideos.current.map((item) => ({ ...item.file })) })
          .catch((error) => console.error('Error deleting video', error));
      }
      if (newHots.current.length > 0) {
        instance
          .post('/delete-files', { files: newHots.current.map((item) => ({ ...item.file })) })
          .catch((error) => console.error('Error deleting hot content', error));
      }
      if (newAvatars.current.length > 0) {
        instance
          .post('/delete-files', { files: newAvatars.current.map((item) => ({ ...item.file })) })
          .catch((error) => console.error('Error deleting avatar', error));
      }
    }
  };

  useEffect(() => {
    return cleanupFiles;
  }, []);

  const handleResetFormInputs = () => {
    const formKeys = Object.keys(form);
    formKeys.map((fieldName: string) => {
      return setForm((prev) => ({
        ...prev,
        [fieldName]: {
          ...prev[fieldName],
          ...(prev[fieldName].hasOwnProperty('value') && { value: '' }),
          ...(prev[fieldName].hasOwnProperty('currentOption') && { currentOption: 0 }),
        },
      }));
    });
  };

  const handleResetForm = () => {
    handleResetFormInputs();
    setPhotos([]);
    setVideos([]);
    setHots([]);
    setAvatars([]);
  };

  const getRequestDate = () => ({
    ...(questionnaireDetails.id && { id: questionnaireDetails.id }),
    firstNameRu: form['firstNameRu'].value as string,
    firstNameEn: form['firstNameEn'].value as string,
    firstNameDe: form['firstNameDe'].value as string,
    descriptionRu: form['descriptionRu'].value as string,
    descriptionEn: form['descriptionEn'].value as string,
    descriptionDe: form['descriptionDe'].value as string,
    ...(form['age'].value && { age: form['age'].value as number }),
    ...(form['height'].value && { height: form['height'].value as number }),
    ...(form['weight'].value && { weight: form['weight'].value as number }),
    ...(form['bustSize'].value && { bustSize: form['bustSize'].value as number }),
    priceAccess: 30000 as number,
    priceHotSubscription: 40000 as number,
    isDemo: false,
    linkToInstagram: form['linkToInstagram'].value as string,
    linkToYoutube: form['linkToYoutube'].value as string,
    ...(form['plannedPublication'].value && {
      plannedPublication: new Date(form['plannedPublication'].value).toISOString() as string,
    }),
    ...(form['limitShowQuestionnaireFinish'].value && {
      limitShowQuestionnaireFinish: new Date(
        form['limitShowQuestionnaireFinish'].value
      ).toISOString() as string,
    }),
    isHidden: false,
    ...(form['cityId'].currentOption && { cityId: form['cityId'].currentOption as number }),
    ...(form['catalogs'].currentOption && {
      catalogs: [form['catalogs'].currentOption] as number[],
    }),
    ...(form['types'].currentOption && { types: [form['types'].currentOption] as number[] }),
    ...(form['formats'].currentOption && { formats: [form['formats'].currentOption] as number[] }),
    photos: photos?.map((item) => ({
      ...item.file,
    })),
    videos: videos?.map((item) => ({
      ...item.file,
    })),
    hots: hots?.map((item) => ({
      ...item.file,
      price: item.price,
    })),
    avatars: avatars?.map((item) => ({
      ...item.file,
    })),
    phone,
  });

  const clearDeletedNewFilesWhileUpdating = () => {
    if (isUpdateMode) {
      const photosToDelete = newPhotos.current.filter(
        (newPhotosItem) =>
          photos && !photos.some((photosItem) => photosItem.file.full === newPhotosItem.file.full)
      );

      const avatarsToDelete = newAvatars.current.filter(
        (newAvatarsItem) =>
          avatars &&
          !avatars.some((avatarsItem) => avatarsItem.file.full === newAvatarsItem.file.full)
      );

      const videosToDelete = newVideos.current.filter(
        (newVideosItem) =>
          videos &&
          !videos.some(
            (videosItem) => videosItem.file.correlationId === newVideosItem.file.correlationId
          )
      );

      const hotsToDelete = newHots.current.filter(
        (newHotsItem) =>
          hots &&
          !hots.some((hotsItem) => {
            const newHotsKey = newHotsItem.file.correlationId || newHotsItem.file.full;
            const hotsKey = hotsItem.file.correlationId || hotsItem.file.full;
            return newHotsKey === hotsKey;
          })
      );

      if (
        photosToDelete.length ||
        avatarsToDelete.length ||
        videosToDelete.length ||
        hotsToDelete.length
      ) {
        instance
          .post('/delete-files', {
            files: [
              ...photosToDelete.map((photo) => ({ ...photo.file })),
              ...avatarsToDelete.map((avatar) => ({ ...avatar.file })),
              ...videosToDelete.map((video) => ({ ...video.file })),
              ...hotsToDelete.map((hot) => ({ ...hot.file })),
            ],
          })
          .catch((error) => console.error('Error deleting file', error));
      }
    }
  };

  const handleCreateQuestionnaire = () => {
    isCreatedOrChanged.current = true;

    dispatch(createQuestionnaire(getRequestDate()))
      .then((action: any) => {
        if (action.meta.requestStatus === 'fulfilled') {
          handleResetForm();
          showToast({
            message: 'Анкета успешно создана, чтобы посмотреть перейдите на страницу Каталоги',
            variant: ToastVariants.SUCCESS,
          });
        } else if (action.meta.requestStatus === 'rejected' && action.payload) {
          showToast({
            message: 'Ошибка при создании анкеты. Проверьте заполнение полей.',
            variant: ToastVariants.ERROR,
          });
        }
      })
      .catch((error) => {
        console.error('Ошибка при создании анкеты:', error);
        showToast({
          message: 'Произошла ошибка при создании анкеты. Попробуйте снова позже.',
          variant: ToastVariants.ERROR,
        });
      });
  };

  const handleChangeQuestionnaire = () => {
    isCreatedOrChanged.current = true;
    clearDeletedNewFilesWhileUpdating();

    dispatch(updateQuestionnaire(getRequestDate()))
      .then((action: any) => {
        if (action.meta.requestStatus === 'fulfilled') {
          showToast({
            message: 'Анкета успешно отредактирована',
            variant: ToastVariants.SUCCESS,
          });
        } else if (action.meta.requestStatus === 'rejected' && action.payload) {
          showToast({
            message: 'Ошибка при изменении анкеты. Проверьте заполнение полей.',
            variant: ToastVariants.ERROR,
          });
        }
      })
      .catch((error) => {
        console.error('Ошибка при создании анкеты:', error);
        showToast({
          message: 'Произошла ошибка при изменении анкеты. Попробуйте снова позже.',
          variant: ToastVariants.ERROR,
        });
      });
  };

  return {
    photos,
    setPhotos,
    videos,
    setVideos,
    hots,
    setHots,
    avatars,
    setAvatars,
    phone,
    setPhone,
    handleChangeQuestionnaire,
    form: {
      ...form,
      countryId: {
        ...form.countryId,
        onClickItem: handleCountryChange,
      },
      cityId: {
        ...form.cityId,
        onClickItem: handleCityChange,
      },
      catalogs: {
        ...form.catalogs,
        onClickItem: handleCatalogsChange,
      },
      types: {
        ...form.types,
        onClickItem: handleTypesChange,
      },
      formats: {
        ...form.formats,
        onClickItem: handleFormatsChange,
      },
    },
    handleCreateQuestionnaire,
    handleChangeForm,
  };
};

const createInitialForm = (
  details: Partial<QuestionnaireDetails>,
  selects: { countryId: number; cityId: number; catalogs: number; types: number; formats: number }
): FormType => {
  return {
    firstNameRu: {
      type: 'input',
      id: 0,
      isValid: false,
      placeholder: 'Имя (ru)',
      isRequired: true,
      value: details.firstName?.ru || '',
      errorText: '',
    },
    firstNameEn: {
      type: 'input',
      id: 1,
      isValid: false,
      placeholder: 'Имя (en)',
      isRequired: true,
      value: details.firstName?.en || '',
      errorText: '',
    },
    firstNameDe: {
      type: 'input',
      id: 2,
      isValid: false,
      placeholder: 'Имя (de)',
      isRequired: true,
      value: details.firstName?.de || '',
      errorText: '',
    },
    descriptionRu: {
      type: 'textarea',
      id: 3,
      isValid: false,
      placeholder: 'Описание (ru)',
      isRequired: true,
      value: details.description?.ru || '',
      errorText: '',
    },
    descriptionEn: {
      type: 'textarea',
      id: 4,
      isValid: false,
      placeholder: 'Описание (en)',
      isRequired: true,
      value: details.description?.en || '',
      errorText: '',
    },
    descriptionDe: {
      type: 'textarea',
      id: 5,
      isValid: false,
      placeholder: 'Описание (de)',
      isRequired: true,
      value: details.description?.de || '',
      errorText: '',
    },
    countryId: {
      type: 'select',
      id: 6,
      isValid: false,
      placeholder: 'Страна',
      isRequired: true,
      currentOption: selects.countryId || 0,
    },
    cityId: {
      type: 'select',
      id: 7,
      isValid: false,
      placeholder: 'Город',
      isRequired: true,
      currentOption: selects.cityId,
    },
    formats: {
      type: 'select',
      id: 8,
      isValid: false,
      placeholder: 'Формат знакомства',
      isRequired: true,
      currentOption: selects.formats,
    },
    catalogs: {
      type: 'select',
      id: 9,
      isValid: false,
      placeholder: 'Каталог',
      isRequired: true,
      currentOption: selects.catalogs,
    },
    types: {
      type: 'select',
      id: 10,
      isValid: false,
      placeholder: 'Типаж',
      isRequired: true,
      currentOption: selects.types,
    },
    age: {
      type: 'input',
      id: 11,
      isValid: false,
      placeholder: 'Возраст',
      isRequired: true,
      value: details.age || 0,
      errorText: '',
    },
    height: {
      type: 'input',
      id: 12,
      isValid: false,
      placeholder: 'Рост',
      isRequired: true,
      value: details.height || 0,
      errorText: '',
    },
    weight: {
      type: 'input',
      id: 13,
      isValid: false,
      placeholder: 'Вес',
      isRequired: true,
      value: details.weight || 0,
      errorText: '',
    },
    bustSize: {
      type: 'input',
      id: 14,
      isValid: false,
      placeholder: 'Грудь',
      isRequired: true,
      value: details.bustSize || 0,
      errorText: '',
    },
    linkToInstagram: {
      type: 'input',
      id: 15,
      isValid: false,
      placeholder: 'Ссылка Instagram',
      isRequired: true,
      value: details.linkToInstagram || '',
      errorText: '',
    },
    linkToYoutube: {
      type: 'input',
      id: 16,
      isValid: false,
      placeholder: 'Ссылка Youtube',
      isRequired: true,
      value: details.linkToYoutube || '',
      errorText: '',
    },
    plannedPublication: {
      type: 'calendar',
      id: 17,
      isValid: false,
      placeholder: 'Запланировать публикацию',
      isRequired: false,
      value: details.plannedPublication,
    },
    limitShowQuestionnaireFinish: {
      type: 'calendar',
      id: 18,
      isValid: false,
      placeholder: 'Ограничение времени на сервисе',
      isRequired: false,
      value: details.limitShowQuestionnaireFinish,
    },
  };
};
