import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/store';

import { useToast } from '../useToast';
import { FormType } from '../../types/FormType/FormType';
import { CatalogDetails } from '../../../entities/catalogs/types';
import { createCatalog } from '../../../entities/catalogs/api';
import { ToastVariants } from '../../context/ToastContext/interfaces';

export const useCatalog = ({
  catalogDetails = {},
  isUpdateMode = false,
}: {
  catalogDetails?: Partial<CatalogDetails>;
  isUpdateMode?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { errors } = useAppSelector((store) => store.catalogs);
  const [form, setForm] = useState(() => createInitialForm(catalogDetails));
  const { showToast } = useToast();

  useEffect(() => {
    setForm((prevForm) => {
      const updatedForm = { ...prevForm };
      Object.keys(prevForm).forEach((fieldName) => {
        updatedForm[fieldName] = {
          ...prevForm[fieldName],
          errorText: errors?.[fieldName] || '',
          isValid: !errors || !errors[fieldName],
        };
      });
      return updatedForm;
    });
  }, [errors]);

  const handleChangeForm = useCallback((fieldName: string, value?: string | number) => {
    setForm((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        value: value,
      },
    }));
  }, []);

  const handleResetFormInputs = () => {
    const formKeys = Object.keys(form);
    formKeys.map((fieldName: string) => {
      return setForm((prev) => ({
        ...prev,
        [fieldName]: {
          ...prev[fieldName],
          ...(prev[fieldName].hasOwnProperty('value') && { value: '' }),
          ...(prev[fieldName].hasOwnProperty('currentOption') && { currentOption: 0 }),
        },
      }));
    });
  };

  const handleResetForm = () => {
    handleResetFormInputs();
  };

  const getRequestDate = () => ({
    ...(catalogDetails.id && { id: catalogDetails.id }),
    titleRu: form['titleRu'].value as string,
    titleEn: form['titleEn'].value as string,
    titleDe: form['titleDe'].value as string,
    subtitleRu: form['subtitleRu'].value as string,
    subtitleEn: form['subtitleEn'].value as string,
    subtitleDe: form['subtitleDe'].value as string,
    descriptionRu: form['descriptionRu'].value as string,
    descriptionEn: form['descriptionEn'].value as string,
    descriptionDe: form['descriptionDe'].value as string,
    ...(form['tagRu'].value && { tagRu: form['tagRu'].value as string }),
    ...(form['tagEn'].value && { tagEn: form['tagEn'].value as string }),
    ...(form['tagDe'].value && { tagDe: form['tagDe'].value as string }),
    ...(form['classname'].value && { classname: form['classname'].value as string }),
    ...(form['price'].value && { price: form['price'].value as number }),
    ...(form['priceDemo'].value && { priceDemo: form['priceDemo'].value as number }),
    ...(form['priceSubscription'].value && {
      priceSubscription: form['priceSubscription'].value as number,
    }),
    preOrder: false,
    ...(form['plannedPublication'].value && {
      plannedPublication: new Date(form['plannedPublication'].value).toISOString() as string,
    }),
  });

  const handleCreateCatalog = () => {
    dispatch(createCatalog(getRequestDate()))
      .then((action: any) => {
        if (action.meta.requestStatus === 'fulfilled') {
          handleResetForm();
          showToast({
            message: 'Каталог успешно создан, чтобы посмотреть перейдите на страницу Каталоги',
            variant: ToastVariants.SUCCESS,
          });
        } else if (action.meta.requestStatus === 'rejected' && action.payload) {
          showToast({
            message: 'Ошибка при создании каталога. Проверьте заполнение полей.',
            variant: ToastVariants.ERROR,
          });
        }
      })
      .catch((error) => {
        console.error('Ошибка при создании каталога:', error);
        showToast({
          message: 'Произошла ошибка при создании каталога. Попробуйте снова позже.',
          variant: ToastVariants.ERROR,
        });
      });
  };

  return {
    form,
    handleChangeForm,
    handleCreateCatalog,
  };
};

const createInitialForm = (details: Partial<CatalogDetails>): FormType => {
  return {
    titleRu: {
      type: 'input',
      id: 0,
      isValid: false,
      placeholder: 'Название (ru)',
      isRequired: true,
      value: details.title?.ru || '',
      errorText: '',
    },
    titleEn: {
      type: 'input',
      id: 1,
      isValid: false,
      placeholder: 'Название (en)',
      isRequired: true,
      value: details.title?.en || '',
      errorText: '',
    },
    titleDe: {
      type: 'input',
      id: 2,
      isValid: false,
      placeholder: 'Название (de)',
      isRequired: true,
      value: details.title?.de || '',
      errorText: '',
    },
    subtitleRu: {
      type: 'input',
      id: 0,
      isValid: false,
      placeholder: 'Подпись (ru)',
      isRequired: true,
      value: details.subtitle?.ru || '',
      errorText: '',
    },
    subtitleEn: {
      type: 'input',
      id: 1,
      isValid: false,
      placeholder: 'Подпись (en)',
      isRequired: true,
      value: details.subtitle?.en || '',
      errorText: '',
    },
    subtitleDe: {
      type: 'input',
      id: 2,
      isValid: false,
      placeholder: 'Подпись (de)',
      isRequired: true,
      value: details.subtitle?.de || '',
      errorText: '',
    },
    descriptionRu: {
      type: 'textarea',
      id: 3,
      isValid: false,
      placeholder: 'Описание каталога (ru)',
      isRequired: true,
      value: details.description?.ru || '',
      errorText: '',
    },
    descriptionEn: {
      type: 'textarea',
      id: 4,
      isValid: false,
      placeholder: 'Описание каталога (en)',
      isRequired: true,
      value: details.description?.en || '',
      errorText: '',
    },
    descriptionDe: {
      type: 'textarea',
      id: 5,
      isValid: false,
      placeholder: 'Описание каталога (de)',
      isRequired: true,
      value: details.description?.de || '',
      errorText: '',
    },
    tagRu: {
      type: 'input',
      id: 0,
      isValid: false,
      placeholder: 'Тег (ru)',
      isRequired: true,
      value: details.tag?.ru || '',
      errorText: '',
    },
    tagEn: {
      type: 'input',
      id: 1,
      isValid: false,
      placeholder: 'Тег (en)',
      isRequired: true,
      value: details.tag?.en || '',
      errorText: '',
    },
    tagDe: {
      type: 'input',
      id: 2,
      isValid: false,
      placeholder: 'Тег (de)',
      isRequired: true,
      value: details.tag?.de || '',
      errorText: '',
    },
    price: {
      type: 'input',
      id: 11,
      isValid: false,
      placeholder: 'Стоимость ($)',
      isRequired: true,
      value: details.price || 0,
      errorText: '',
    },
    priceDemo: {
      type: 'input',
      id: 11,
      isValid: false,
      placeholder: 'Стоимость демо ($)',
      isRequired: true,
      value: details.priceDemo || 0,
      errorText: '',
    },
    priceSubscription: {
      type: 'input',
      id: 11,
      isValid: false,
      placeholder: 'Стоимость подписки ($)',
      isRequired: true,
      value: details.priceSubscription || 0,
      errorText: '',
    },
    classname: {
      type: 'input',
      id: 1,
      isValid: false,
      placeholder: 'Градиент',
      isRequired: true,
      value: details.classname || '',
      errorText: '',
    },
    plannedPublication: {
      type: 'calendar',
      id: 17,
      isValid: false,
      placeholder: 'Запланировать публикацию',
      isRequired: false,
      value: details.plannedPublication,
    },
  };
};
