import { QuestionnairyItem } from 'entities/questionnaires/ui/QuestionnairesItem';

import styles from './QuestionneiresList.module.scss';
import { QuestionnairesListProps } from './QuestionneiresList.props';

export const QuestionnairesList = ({ questionnairesList }: QuestionnairesListProps) => {
  return (
    <div className={styles.list}>
      {questionnairesList.map(({ id, age, firstName, priceAccess, avatars, catalogs }) => (
        <QuestionnairyItem
          id={id}
          key={id}
          avatars={avatars}
          priceAccess={priceAccess}
          age={age}
          catalogs={catalogs}
          firstName={firstName}
        />
      ))}
    </div>
  );
};
