import { IMAGES_URL } from 'shared/config/urls';

import { ReactComponent as TrashIcon } from '../../../../shared/assets/icons/trash.svg';
import styles from './PhotoListItem.module.scss';
import { PhotoListItemPropsI } from './PhotoListItem.props';
import processing from '../../../../shared/assets/mock/processing.jpg';

export const PhotoListItem = ({ file, handleRemove }: PhotoListItemPropsI) => {
  return (
    <li className={styles.item}>
      <img
        className={styles.image}
        src={file.preview ? `${IMAGES_URL}${file.preview}` : processing}
        alt="preview"
      />
      <button onClick={() => handleRemove(file)}>
        <TrashIcon className={styles.trash_icon} />
      </button>
    </li>
  );
};
