import { createSlice } from '@reduxjs/toolkit';

import { createCatalog, fetchAllCatalogs } from '../api';
import { CatalogsInitialState } from './interfaces';

const initialState: CatalogsInitialState = {
  catalogs: [],
  loading: false,
  errors: null,
};
const catalogsSlice = createSlice({
  name: 'catalogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all catalogs
    builder.addCase(fetchAllCatalogs.pending, (state) => {
      state.catalogs = [];
    });
    builder.addCase(fetchAllCatalogs.fulfilled, (state, action) => {
      state.catalogs = action.payload;
    });
    builder.addCase(fetchAllCatalogs.rejected, (state) => {
      state.catalogs = [];
    });

    // create catalog
    builder.addCase(createCatalog.fulfilled, (state, action) => {
      state.errors = null;
      state.loading = false;
    });
    builder.addCase(createCatalog.rejected, (state, action) => {
      if (action.payload) {
        state.errors = action.payload as Record<string, string>;
      } else {
        state.errors = { general: 'Unexpected error occurred' };
      }
      state.loading = false;
    });
  },
});
export const {} = catalogsSlice.actions;
export default catalogsSlice.reducer;
