import { useCallback, useEffect, useState } from 'react';
import { SelectFilters } from 'widgets/SelectFilters';
import { QuestionnairesList } from 'widgets/Questionnaires/QuestionneiresList';
import { QuickFilters } from 'widgets/Questionnaires/QuickFilters';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  fetchFormats,
  getAllQuestionnaires,
  getCatalogs,
  getCities,
  getCountries,
} from 'entities/questionnaires/api';
import { generateQueryString } from 'shared/helpers/generateQueryString';

import { ReactComponent as CloseSquareIcon } from '../../../../shared/assets/icons/closesquare.svg';
import { ReactComponent as ArrowIcon } from '../../../../shared/assets/icons/arrowthree.svg';
import { ReactComponent as ArrowIconRotate } from '../../../../shared/assets/icons/arrowsecond.svg';
import styles from './QuestionnaireTab.module.scss';
import { PAGE_SIZE } from '../../../../shared/constans/common';
import { Pagination } from '../../../../shared/ui/Pagination';

export const QuestionnaireTab = () => {
  const dispatch = useAppDispatch();
  const { questionnaires, countries, cities, formats, catalogNames, meta } = useAppSelector(
    (state) => state.questionnaires
  );

  const [page, setPage] = useState(1);
  const [selects, setSelects] = useState({
    status: 0,
    meet: 0,
    country: 0,
    city: 0,
  });
  const [quickFilters, setQuickFilters] = useState<number[]>([]);

  const meetings = [...formats]
    .sort((a, b) => a.order - b.order)
    .map((f) => ({ id: f.id, value: f.name['ru'] }));

  const statuses = [
    {
      value: 'Дата добавления',
      id: 1,
      Icon: ArrowIconRotate,
    },
    {
      value: 'Дата добавления',
      id: 2,
      Icon: ArrowIcon,
    },
    {
      value: 'Популярность',
      id: 3,
    },
  ];

  const { status, meet, country, city } = selects;

  const SortedDetailsStatus: { [key: number]: string } = {
    0: 'null',
    1: 'DESC',
    2: 'ASC',
    3: 'null',
  };

  const qParams = {
    page: page,
    limit: PAGE_SIZE,
    countryId: selects.country !== 0 ? selects.country : null,
    cityId: selects.city !== 0 ? selects.city : null,
    catalogIds: quickFilters.length ? quickFilters : null,
    sort:
      SortedDetailsStatus[selects.status] !== 'null' ? SortedDetailsStatus[selects.status] : null,
    formatIds: selects.meet !== 0 ? selects.meet : null,
    popular: SortedDetailsStatus[selects.status] === 'null' ? true : null,
  };

  const handleClickSetCatalog = useCallback(
    (catalog: number) => {
      const isCatalogIncluded = quickFilters.includes(catalog);
      if (isCatalogIncluded) {
        setQuickFilters((prev) => prev.filter((item) => item !== catalog));
      } else {
        setQuickFilters((prev) => [...prev, catalog]);
      }
    },
    [quickFilters]
  );

  const handleResetButton = () => {
    setQuickFilters([]);
    setSelects({ status: 0, city: 0, country: 0, meet: 0 });
  };

  const DetailsSelect = [
    {
      options: statuses,
      placeholder: 'Сортировать',
      onClickItem: (id: number) => setSelects({ ...selects, status: id }),
      currentOption: status,
    },
    {
      options: meetings,
      placeholder: 'Формат знакомств',
      onClickItem: (id: number) => setSelects({ ...selects, meet: id }),
      currentOption: meet,
    },
    {
      options: countries.map(({ id, name }) => ({
        id: id,
        value: name['ru'],
      })),
      placeholder: 'Страна',
      onClickItem: (id: number) => {
        setSelects({ ...selects, country: id, city: 0 });
      },
      currentOption: country,
    },
    {
      options: cities.map(({ id, name }) => ({
        id: id,
        value: name['ru'],
      })),
      placeholder: 'Город',
      onClickItem: (id: number) => {
        const cityCountryId = cities.find((obj) => obj.id === id)?.countryId;
        const countryId = countries.find(({ id }) => id === cityCountryId)?.id;
        setSelects({ ...selects, city: id, country: countryId ? countryId : 0 });
      },
      currentOption: city,
    },
  ];

  useEffect(() => {
    dispatch(getAllQuestionnaires(generateQueryString(qParams)));
  }, [
    dispatch,
    page,
    selects.city,
    selects.country,
    quickFilters.length,
    selects.status,
    selects.meet,
  ]);

  useEffect(() => {
    dispatch(getCatalogs());
    dispatch(fetchFormats());
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    dispatch(getCities({ countryId: selects.country }));
  }, [dispatch, selects.country]);

  return (
    <div>
      <SelectFilters detailsSelect={DetailsSelect} />
      <div className={`${styles.quickFilters}`}>
        <QuickFilters
          catalogs={catalogNames}
          quickFilters={quickFilters}
          handleClickSetCatalog={handleClickSetCatalog}
        />
        <button onClick={handleResetButton} className={styles.reset_button}>
          Сбросить все фильтры
          <CloseSquareIcon />
        </button>
      </div>
      <QuestionnairesList questionnairesList={questionnaires} />
      {meta.totalPages > 1 && (
        <Pagination
          currentPage={meta.currentPage}
          previousPage={meta.previousPage}
          nextPage={meta.nextPage}
          totalPages={meta.totalPages}
          changePage={(page) => setPage(page)}
          disabled={false}
          additionalClasses={styles.pagination}
        />
      )}
    </div>
  );
};
