import { SyntheticEvent, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from '../../assets//icons/arrow.svg';
import styles from './Select.module.scss';
import { SelectProps } from './Select.props';
import { useOutsideClick } from '../../hooks/useOutsideClick/useOutsideClick';
import { useKeyDownOutside } from '../../hooks/useKeyDownOutside/useKeyDownOutside';
import { BUTTONS_ON_KEYBOARD } from '../../constans/buttonOnKeyboard/buttonOnKeyboard';

const DEFAULT_PADDING = 10;

export const Select = ({
  options,
  onClickItem,
  currentOption,
  className,
  isDisabled,
  placeholder,
  tip,
  tipAsPlaceholder,
  v,
  status = '',
  iconOptionsClassname,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);

  const selectClassName = classNames(
    styles.container,
    status && styles[status],
    className && className
  );
  const buttonClassName = classNames(styles.button, isOpen && styles.select_open);
  const selectRect = selectRef?.current?.getBoundingClientRect();
  const topPosition = selectRect ? selectRect.top + selectRect.height + DEFAULT_PADDING : null;

  const getCurrentOption = () => {
    return (
      (typeof currentOption === 'string' && currentOption) || currentValueInOptions || placeholder
    );
  };

  useOutsideClick(selectRef, () => setIsOpen(false), isOpen);
  useKeyDownOutside(selectRef, () => setIsOpen(false), isOpen, BUTTONS_ON_KEYBOARD.Enter);

  const handleClickOpenSelect = () => {
    setIsOpen((prev) => !prev);
  };

  const currentValueInOptions = options.find(
    ({ id }) => typeof currentOption === 'number' && id === currentOption
  )?.value;

  const CurrentIconInOptions = options.find(
    ({ id }) => typeof currentOption === 'number' && id === currentOption
  )?.Icon;

  return (
    <div ref={selectRef} className={`${selectClassName} ${v ? styles[`v${v}`] : ''}`}>
      {tip && (
        <span
          className={`${styles.tip} ${
            tipAsPlaceholder && !currentOption ? styles['tip-placeholder'] : ''
          }`}
        >
          {tip}
        </span>
      )}
      <button disabled={isDisabled} onClick={handleClickOpenSelect} className={buttonClassName}>
        {getCurrentOption()}
        {CurrentIconInOptions && <CurrentIconInOptions className={`${iconOptionsClassname}`} />}
        <span className={styles.icon}>
          <ArrowIcon className={styles.animation_icon} />
        </span>
      </button>
      {isOpen &&
        ReactDOM.createPortal(
          <ul
            style={{
              top: `${topPosition}px`,
              left: `${selectRect?.left}px`,
              width: `${selectRect?.width}px`,
            }}
            className={`${styles.select_list} ${v ? styles[`v${v}`] : ''}`}
          >
            {options.map(({ value, id, Icon }) => (
              <li key={id}>
                <button
                  onClick={() => {
                    onClickItem(id);
                    setIsOpen(false);
                  }}
                  onMouseDown={(event: SyntheticEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  className={classNames(
                    styles.button,
                    styles.list_button,
                    currentOption === value && styles.active_button
                  )}
                >
                  {value}
                  {Icon && <Icon className={`${styles.icon_value} ${iconOptionsClassname}`} />}
                </button>
              </li>
            ))}
          </ul>,
          document.body
        )}
    </div>
  );
};
