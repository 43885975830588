import { useEffect, useState, useCallback } from 'react';
import { io, Socket } from 'socket.io-client';

const URL = `${process.env.REACT_APP_PRODUCTION_URL}`.replace('/api', '');
const PATH = process.env.REACT_APP_SOCKET_PATH || '/socket.io';
let socket: Socket | null = null;

export const useSocket = () => {
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (!socket) {
      socket = io(URL, {
        autoConnect: true,
        path: PATH,
      });

      socket.on('connect', () => setIsConnected(true));
      socket.on('disconnect', () => setIsConnected(false));
    }

    return () => {
      if (socket) {
        socket.disconnect();
        socket = null;
      }
    };
  }, []);

  const joinRoom = useCallback((room: string | number) => {
    if (socket) {
      socket.emit('join-room', room);
    }
  }, []);

  const leaveRoom = useCallback((room: string) => {
    if (socket) {
      socket.emit('leave-room', room);
    }
  }, []);

  const onEvent = useCallback((eventType: string, handler: (data: any) => void) => {
    if (socket) {
      socket.on(eventType, handler);
    }
  }, []);

  const offEvent = useCallback((eventType: string, handler?: (data: any) => void) => {
    if (socket) {
      if (handler) {
        socket.off(eventType, handler);
      } else {
        socket.off(eventType);
      }
    }
  }, []);

  const emitEvent = useCallback((eventType: string, data: any) => {
    if (socket) {
      socket.emit(eventType, data);
    }
  }, []);

  return {
    isConnected,
    joinRoom,
    leaveRoom,
    onEvent,
    offEvent,
    emitEvent,
  };
};
