import { ChangeEvent, useEffect, useState, DragEvent } from 'react';
import { FileType } from 'shared/types/FileType';

import { instance } from '../../config/api/api';

export const useFileUpload = (setFiles: any, files: FileType[]) => {
  const [isDrag, setIsDrag] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [processingFileName, setProcessingFileName] = useState('');
  const [isSelected, setIsSelected] = useState(false);

  const handleFileSelect = (files: FileList) => {
    if (files) {
      const filesArray = Array.from(files);
      setSelectedFiles([...filesArray, ...selectedFiles]);
      setIsSelected(true);
    }
  };

  const handeFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleFileSelect(event.target.files);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    setIsDrag(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    setIsDrag(false);
  };

  const handleDrop = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    handleFileSelect(event.dataTransfer.files);
    setIsDrag(false);
  };

  // const handleClickRemoveFile = (name: string) => {
  //   setFiles((prev: { id: number; full: string; preview: string; type: 'image' | 'video' }[]) =>
  //     prev.filter(({ preview }) => preview !== name)
  //   );
  // };
  const handleClickRemoveFile = (identifier: string) =>
    setFiles((prev: { correlationId?: string; preview?: string }[]) =>
      prev.filter((file) => file.preview !== identifier && file.correlationId !== identifier)
    );

  useEffect(() => {
    if (isSelected) {
      handleUpload();
    }
  }, [isSelected]);

  const handleUpload = async () => {
    for await (const file of selectedFiles) {
      try {
        const formData = new FormData();
        formData.append('file', file);

        setProcessingFileName(file.name);

        const { data } = await instance.post(
          `${process.env.REACT_APP_PRODUCTION_URL}/upload`,
          formData
        );

        const { video, correlationId, type, ...rest } = data?.file;

        if (type === 'image') {
          const file = {
            type,
            ...rest,
          };
          setFiles((prev: FileType[][]) => [file, ...prev]);
        } else {
          const file = {
            type,
            video,
            correlationId,
            ...rest,
          };
          setFiles((prev: FileType[][]) => [file, ...prev]);
        }
      } catch (error) {
        console.log('ошибка');
      } finally {
        setSelectedFiles([]);
        setIsSelected(false);
      }
    }
    setProcessingFileName('');
  };

  return {
    selectedFiles,
    handeFileChange,
    processingFileName,
    handleClickRemoveFile,
    handleDrop,
    handleDragLeave,
    handleDragOver,
  };
};
