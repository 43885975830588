import { useState, MouseEvent } from 'react';

const MINIMUM_FINGER_MOVEMENT_SPEED = 5;

export const useSwither = (images: any) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [touchPosition, setTouchPosition] = useState<number | null>(null);

  const notLastElementOnArray = currentImageIndex !== images?.length - 1;
  const notFirstElementOnArray = currentImageIndex !== 0;

  const handleButtonClick = (event: MouseEvent, index: number) => {
    event.preventDefault();
    setCurrentImageIndex(index);
  };

  const handleButtonMouseEnter = (index: number) => {
    setCurrentImageIndex(index);
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    const touchDown = event.touches[0].clientX;
    setTouchPosition(touchDown);
  };
  const handleTouchMove = (event: React.TouchEvent) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = event.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > MINIMUM_FINGER_MOVEMENT_SPEED && notLastElementOnArray) {
      setCurrentImageIndex((prev) => prev + 1);
    }

    if (diff < -MINIMUM_FINGER_MOVEMENT_SPEED && notFirstElementOnArray) {
      setCurrentImageIndex((prev) => prev - 1);
    }

    setTouchPosition(null);
  };

  return {
    currentImageIndex,
    handleButtonClick,
    handleButtonMouseEnter,
    handleTouchMove,
    handleTouchStart,
    setCurrentImageIndex,
  };
};
