import { ReactElement } from 'react';
import { QuestionnairyItem } from 'entities/questionnaires/ui/QuestionnairesItem';

import styles from './ItemsList.module.scss';
import { Questionnaire } from '../../../entities/questionnaires/types';

type ItemsListPropsT = {
  btnText: string | ReactElement;
  btnHandler: () => void;
  list: Array<Questionnaire>;
  type: 'CatalogType';
};

export const ItemsList = ({ btnText, btnHandler, list, type }: ItemsListPropsT) => {
  let emptyFields = [];
  if (list.length < 3) {
    for (let i = 0; i < 3 - list.length; i++) {
      emptyFields.push(<div className="filler-block" key={i}></div>);
    }
  }

  return (
    <div className={styles.list}>
      <div className={styles.btn} onClick={btnHandler}>
        {btnText}
      </div>
      {list.map((i, index) => {
        if (type === 'CatalogType') {
          return (
            <QuestionnairyItem
              key={i.id}
              age={24}
              catalogs={i.catalogs}
              firstName={i.firstName}
              id={i.id}
              avatars={i.avatars}
            />
          );
        } else {
          return <div key={index}>yo</div>;
        }
      })}
      {emptyFields}
    </div>
  );
};
