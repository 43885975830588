import { Input } from 'shared/ui/Input';
import { CalendarCustom } from 'shared/ui/Calendar';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'shared/constans/routes';

import styles from './CreateAuctionForm.module.scss';
import { ReactComponent as BackButtonIcon } from '../../../shared/assets/icons/arrowsquareleft.svg';
import { CreateAuctionFormPropsI } from './CreateAuctionForm.props';

export const CreateAuctionForm = ({
  form,
  handleChangeForm,
  isChangePage = false,
}: CreateAuctionFormPropsI) => {
  const navigate = useNavigate();

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <button onClick={() => navigate(`${ROUTES.AUCTIONS}`)} className={styles.back_button}>
          <BackButtonIcon />
        </button>
        <p className={styles.title}>
          {isChangePage ? 'Редактировать  аукциона' : 'Создание аукциона'}
        </p>
      </div>
      <ul className={styles.inputs_list}>
        <li className={`${styles.inouts_item} ${styles.first_row}`}>
          <Input
            type={2}
            value={form['name'].value || ''}
            tip={form['name'].placeholder}
            tipAsPlaceholder={true}
            setValue={(val) => handleChangeForm('name', val)}
            isError={!!form['name'].errorText}
          />
          <CalendarCustom
            title={form['startDate'].placeholder}
            date={form['startDate'].value}
            setDate={(date: any) => handleChangeForm('startDate', date)}
            isError={!!form['startDate'].errorText}
          />
          <CalendarCustom
            title={form['endDate'].placeholder}
            date={form['endDate'].value}
            setDate={(date: any) => handleChangeForm('endDate', date)}
            isError={!!form['endDate'].errorText}
          />
        </li>
        <li className={`${styles.inouts_item} ${styles.second_row}`}>
          <Input
            type={2}
            value={form['bet'].value || ''}
            tip={form['bet'].placeholder}
            tipAsPlaceholder={true}
            setValue={(val) => handleChangeForm('bet', val)}
            isError={!!form['bet'].errorText}
            valueType="number"
            pattern="[0-9]*"
            inputmode="numeric"
          />
          {/* {form['bet'].errorText && (
            <span className={styles.valid_error}>{form['bet'].errorText}</span>
          )} */}
          <Input
            type={2}
            value={form['redemption'].value || ''}
            tip={form['redemption'].placeholder}
            tipAsPlaceholder={true}
            setValue={(val) => handleChangeForm('redemption', val)}
            isError={!!form['redemption'].errorText}
            valueType="number"
            pattern="[0-9]*"
            inputmode="numeric"
          />
          {/* {form['redemption'].errorText && (
            <span className={styles.valid_error}>{form['redemption'].errorText}</span>
          )} */}
          <CalendarCustom
            title={form['publish'].placeholder}
            date={form['publish'].value}
            setDate={(date: any) => handleChangeForm('publish', date)}
            isIcon
          />
        </li>
      </ul>
      <textarea
        key={form['description']?.id}
        onChange={(event) => handleChangeForm('description', event.target.value)}
        placeholder={form['description']?.placeholder}
        value={form['description'].value || ''}
        className={`${styles.textarea} ${!!form['description'].errorText && styles.error}`}
      />
    </div>
  );
};
