import { memo, useEffect } from 'react';
import { Button } from 'shared/ui/Button';
import { DeletePhotoButton } from 'features/questionnaire/DeletePhotoButton';
import { UploadModal } from 'widgets/UploadModal';
import { v4 as uuidv4 } from 'uuid';

import styles from './Video.module.scss';
import { ReactComponent as AddIcon } from '../../../shared/assets/icons/addsquare.svg';
import { FileType } from '../../../shared/types/FileType';
import { IMAGES_URL } from '../../../shared/config/urls';
import { VideoPropsI } from './Video.props';
import { QuestionnaireContent } from '../../../entities/questionnaires/types';
import processing from '../../../shared/assets/mock/processing.jpg';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { setIsVisibleUploadModal } from '../../../entities/questionnaires/models';
import { useSocket } from '../../../shared/hooks/useSocket';

export const Video = memo(({ videos, setVideos, isCreateMode }: VideoPropsI) => {
  const { isVisibleUploadModal } = useAppSelector((store) => store.questionnaires);
  const dispatch = useAppDispatch();
  const { joinRoom, leaveRoom, onEvent, offEvent } = useSocket();

  useEffect(() => {
    const room = 'admin-video';
    const event = 'video-upload';
    joinRoom(room);

    const handleVideoUpload = (data: {
      correlationId: string;
      full: string;
      preview: string;
      fullBlur: string;
      previewBlur: string;
      type: 'video' | 'image';
    }) => {
      setVideos((prev) => {
        if (prev) {
          prev.map((item) => {
            if (item.file.correlationId === data.correlationId) {
              console.log('in if');
              item.file = data;
            }
          });
          return [...prev];
        } else {
          return null;
        }
      });
    };

    onEvent(event, handleVideoUpload);

    return () => {
      offEvent(event, handleVideoUpload);
      leaveRoom(room);
    };
  }, [joinRoom, leaveRoom, onEvent, offEvent]);

  const handleSetFiles = (newFiles: FileType[]) => {
    const content = newFiles.map((file) => ({
      id: uuidv4(),
      type: 'video',
      order: videos?.length ? Math.max(...videos.map((item) => item.order || 0)) + 1 : 1,
      file,
    })) as QuestionnaireContent[];
    setVideos((prev) => {
      if (prev) {
        return [...prev, ...content];
      } else {
        return content;
      }
    });
  };

  return (
    <ul className={styles.list}>
      <li className={styles.item}>
        <Button onClick={() => dispatch(setIsVisibleUploadModal(true))} type={6}>
          <AddIcon />
          Добавить
        </Button>
      </li>
      {videos &&
        [...videos]
          ?.sort((a, b) => (a.order || 0) - (b.order || 0))
          .map((video) => (
            <li className={styles.item} key={video.id}>
              <img
                className={styles.image}
                src={video.file.preview ? `${IMAGES_URL}${video.file.preview}` : processing}
                alt="preview"
              />
              <div className={styles.delete}>
                <DeletePhotoButton file={video} setFiles={setVideos} isCreateMode={isCreateMode} />
              </div>
            </li>
          ))}
      <UploadModal
        isVisible={isVisibleUploadModal}
        close={() => dispatch(setIsVisibleUploadModal(false))}
        handleSetFiles={handleSetFiles}
        dropzoneText="Загрузите видео с устройства или перетащите файлы в это поле"
        title="Добавить новое видео"
        accept="video/mp4"
      />
    </ul>
  );
});
