import { ReactComponent as Add } from 'shared/assets/icons/addsquare.svg';
import { ItemsList } from 'shared/ui/ItemsList';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/store';
import { getAllQuestionnaires } from 'entities/questionnaires/api';
import { generateQueryString } from 'shared/helpers/generateQueryString';

import { CatalogQuestionnairesWidgetPropsI } from './CatalogQuestionnairesWidget.props';

export const CatalogQuestionnairesWidget = ({ id }: CatalogQuestionnairesWidgetPropsI) => {
  const dispatch = useAppDispatch();
  const { questionnaires } = useAppSelector((state) => state.questionnaires);

  const qParams = {
    page: 1,
    limit: 10,
    catalogIds: [id],
    popular: true,
  };

  useEffect(() => {
    if (id) {
      dispatch(getAllQuestionnaires(generateQueryString(qParams)));
    }
  }, []);

  return (
    <ItemsList
      list={questionnaires}
      btnText={
        <>
          <Add className="svg_stroke mr-12" /> Добавить анкету
        </>
      }
      btnHandler={() => {}}
      type="CatalogType"
    />
  );
};
