import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import EditQuestionnaire from 'pages/EditQuestionnaire/EditQuestionnaire';
import { EditCatalogPage } from 'pages/EditCatalogPage';
import ROUTES from 'shared/constans/routes';
import CreateQuestionnaire from 'pages/CreateQuestionnaire/CreateQuestionnaire';
import { CreateCatalogPage } from 'pages/CreateCatalogPage';
import { useSetPageTitle } from 'shared/hooks/useSetPageTitle';
import ChangeAuction from 'pages/ChangeAuction/ChangeAuction';
import { Toast } from 'shared/ui/Toast/Toast';
import { useEffect, useState } from 'react';
import News from 'pages/News/News';

import { useAppDispatch, useAppSelector } from '../../../app/store';
import { refresh } from '../../../features/auth/authSlice';
import Users from '../../../pages/Users/Users';
import AdminPage from '../../../pages/AdminPage/AdminPage';
import { SideBar } from '../../../widgets/SideBar/SideBar';
import { QuickNavigation } from '../../../widgets/QuickNavigation/QuickNavigation';
import Catalogs from '../../../pages/Catalogs/Catalogs';
import Chat from '../../../pages/Chat/Chat';
import Favorites from '../../../pages/Favorites/Favorites';
import Auctions from '../../../pages/Auctions/Auctions';
import { Header } from '../../../widgets/Header';
import { Footer } from '../../../widgets/Footer';
import CurrentUser from '../../../pages/CurrentUser/CurrentUser';
import CreateAuction from '../../../pages/CreateAuction/CreateAuction';
import PrivateRoute from '../../../shared/components/PrivateRoutes/PrivateRoutes';
import Login from '../../../pages/Login/Login';
import ErrorPage from '../../../pages/ErrorPage/ErrorPage';

const AppRouter = () => {
  const { pathname } = useLocation();
  const noLayoutPaths = ['/login'];
  const hideLayout = noLayoutPaths.includes(pathname);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authLoading } = useAppSelector((state) => state.auth);
  const [isTokenChecked, setTokenChecked] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        try {
          await dispatch(refresh()).unwrap();
        } catch (error) {
          navigate('/login');
        }
      }
      setTokenChecked(true);
    };

    checkAuth();
  }, []);

  useSetPageTitle();

  const shouldRenderLayout = isTokenChecked && !authLoading && !hideLayout;

  if (!isTokenChecked || authLoading) {
    return null;
  }

  return (
    <div className="wrapper">
      {shouldRenderLayout && <Header />}
      <div
        style={
          !hideLayout ? { display: 'flex', gap: '55px', marginTop: 90, paddingBottom: 90 } : {}
        }
      >
        {shouldRenderLayout && pathname !== '/' && <SideBar className="d-block-none600" />}
        {shouldRenderLayout && pathname !== '/' && <QuickNavigation />}
        <Routes>
          <Route path={'/login'} element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path={'/'} element={<AdminPage />} />
            <Route path={`${ROUTES.USERS}`} element={<Users />} />
            <Route path={`${ROUTES.USERS}/:id`} element={<CurrentUser />} />
            <Route path={ROUTES.CATALOGS} element={<Catalogs />} />
            <Route path={ROUTES.CHAT} element={<Chat />} />
            <Route path={ROUTES.FAVORITES} element={<Favorites />} />
            <Route path={ROUTES.AUCTIONS} element={<Auctions />} />
            <Route path={`${ROUTES.CREATE_AUCTION}`} element={<CreateAuction />} />
            <Route path={`${ROUTES.NEWS}`} element={<News />} />
            <Route path={`${ROUTES.EDIT_QUESTIONNAIRE}/:id`} element={<EditQuestionnaire />} />
            <Route path={`${ROUTES.EDIT_CATALOG}/:id`} element={<EditCatalogPage />} />
            <Route path={`${ROUTES.CHANGE_AUCTION}/:id`} element={<ChangeAuction />} />
            <Route path={`${ROUTES.CREATE_CATALOG}`} element={<CreateCatalogPage />} />
            <Route path={`${ROUTES.CREATE_QUESTIONNAIRE}`} element={<CreateQuestionnaire />} />
          </Route>
          <Route path={`${ROUTES.ERROR}`} element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
      {shouldRenderLayout && <Toast />}
      {shouldRenderLayout && <Footer />}
    </div>
  );
};

export default AppRouter;
