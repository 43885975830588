import { createSlice } from '@reduxjs/toolkit';

import {
  createQuestionnaire,
  deleteQuestionnaire,
  fetchFormats,
  fetchTypes,
  getAllQuestionnaires,
  getCatalogs,
  getCities,
  getCountries,
  getQuestionnaireContents,
  getQuestionnaireDetails,
  updateQuestionnaire,
} from '../api';
import { QuestionnairesInitialState } from './interfaces';

const initialState: QuestionnairesInitialState = {
  questionnaires: [],
  questionnaireContents: [],
  loadingQuestionnaireContents: false,
  questionnaireDetails: null,
  loadingQuestionnaireDetails: false,
  catalogNames: [],
  loadingCatalogs: false,
  countries: [],
  loadingCountries: false,
  cities: [],
  loadingCities: false,
  types: [],
  formats: [],
  loading: false,
  errors: null,
  isVisibleUploadModal: false,
  isVisibleAvatarUploadModal: false,
  meta: {
    currentPage: 1,
    limit: 10,
    nextPage: null,
    previousPage: null,
    totalItems: 0,
    totalPages: 1,
  },
};

const questionnairesSlice = createSlice({
  name: 'questionnaires',
  initialState,
  reducers: {
    clearQuestionnaireContents: (state) => {
      state.questionnaireContents = [];
    },
    clearQuestionnaireErrors: (state) => {
      state.errors = null;
    },
    setIsVisibleUploadModal: (state, action) => {
      state.isVisibleUploadModal = action.payload;
    },
    setIsVisibleAvatarUploadModal: (state, action) => {
      state.isVisibleAvatarUploadModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get all questionnaires
    builder.addCase(getAllQuestionnaires.pending, (state) => {
      state.questionnaires = [];
      state.loading = true;
    });
    builder.addCase(getAllQuestionnaires.fulfilled, (state, action) => {
      state.questionnaires = action.payload.questionnaires;
      state.meta = action.payload.meta;
      state.loading = false;
    });
    builder.addCase(getAllQuestionnaires.rejected, (state) => {
      state.questionnaires = [];
      state.loading = false;
    });

    // get questionnarie details
    builder.addCase(getQuestionnaireDetails.pending, (state) => {
      state.questionnaireDetails = null;
      state.loadingQuestionnaireDetails = true;
    });
    builder.addCase(getQuestionnaireDetails.fulfilled, (state, action) => {
      state.questionnaireDetails = action.payload;
      state.loadingQuestionnaireDetails = false;
    });
    builder.addCase(getQuestionnaireDetails.rejected, (state) => {
      state.questionnaireDetails = null;
      state.loadingQuestionnaireDetails = false;
    });

    // get questionnaire contents
    builder.addCase(getQuestionnaireContents.pending, (state) => {
      state.questionnaireContents = [];
      state.loadingQuestionnaireContents = true;
    });
    builder.addCase(getQuestionnaireContents.fulfilled, (state, action) => {
      state.questionnaireContents = action.payload.questionnaireContents;
      state.loadingQuestionnaireContents = false;
    });
    builder.addCase(getQuestionnaireContents.rejected, (state) => {
      state.questionnaireContents = [];
      state.loadingQuestionnaireContents = false;
    });

    // get catalogs
    builder.addCase(getCatalogs.pending, (state) => {
      state.loadingCatalogs = true;
    });
    builder.addCase(getCatalogs.fulfilled, (state, action) => {
      state.catalogNames = action.payload.catalogs;
      state.loadingCatalogs = false;
    });
    builder.addCase(getCatalogs.rejected, (state) => {
      state.catalogNames = [];
      state.loadingCatalogs = false;
    });

    // get countries
    builder.addCase(getCountries.pending, (state) => {
      state.loadingCountries = true;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload.data;
      state.loadingCountries = false;
    });
    builder.addCase(getCountries.rejected, (state) => {
      state.countries = [];
      state.loadingCountries = false;
    });

    // get cities
    builder.addCase(getCities.pending, (state) => {
      state.loadingCities = true;
    });
    builder.addCase(getCities.fulfilled, (state, action) => {
      state.cities = action.payload.data;
      state.loadingCities = false;
    });
    builder.addCase(getCities.rejected, (state) => {
      state.cities = [];
      state.loadingCities = false;
    });

    // get types
    builder.addCase(fetchTypes.pending, (state) => {});
    builder.addCase(fetchTypes.fulfilled, (state, action) => {
      state.types = action.payload.types;
    });
    builder.addCase(fetchTypes.rejected, (state) => {});

    // get formats
    builder.addCase(fetchFormats.pending, (state) => {});
    builder.addCase(fetchFormats.fulfilled, (state, action) => {
      state.formats = action.payload.formats;
    });
    builder.addCase(fetchFormats.rejected, (state) => {});

    // create questionnaire
    builder.addCase(createQuestionnaire.fulfilled, (state, action) => {
      state.errors = null;
      state.loading = false;
    });
    builder.addCase(createQuestionnaire.rejected, (state, action) => {
      if (action.payload) {
        state.errors = action.payload as Record<string, string>;
      } else {
        state.errors = { general: 'Unexpected error occurred' };
      }
      state.loading = false;
    });

    // update questionnaire
    builder.addCase(updateQuestionnaire.fulfilled, (state, action) => {
      state.errors = null;
      state.loading = false;
    });
    builder.addCase(updateQuestionnaire.rejected, (state, action) => {
      if (action.payload) {
        state.errors = action.payload as Record<string, string>;
      } else {
        state.errors = { general: 'Unexpected error occurred' };
      }
      state.loading = false;
    });

    // delete questionnaire
    builder.addCase(deleteQuestionnaire.fulfilled, (state, action) => {
      state.questionnaires = state.questionnaires.filter(
        (questionnaire) => questionnaire.id !== action.meta.arg
      );
    });
  },
});

export const {
  clearQuestionnaireContents,
  clearQuestionnaireErrors,
  setIsVisibleUploadModal,
  setIsVisibleAvatarUploadModal,
} = questionnairesSlice.actions;

export default questionnairesSlice.reducer;
