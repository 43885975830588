import { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'shared/ui/Modal';
import { Input } from 'shared/ui/Input';
import { Checkbox } from 'shared/ui/Checkbox';
import { Button } from 'shared/ui/Button';

import { ChangePriceModalPropsI, ChangePriceModalRefI } from './ChangePriceModal.props';
import styles from './ChangePriceModal.module.scss';

export const ChangePriceModal = forwardRef<ChangePriceModalRefI, ChangePriceModalPropsI>(
  ({ hots, setHots }, ref) => {
    const [newPrice, setNewPrice] = useState<string>('');
    const [selectedHotId, setSelectedHotId] = useState<string | number | null>(null);
    const [isCheckedAllContent, setIsCheckedAllContent] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useImperativeHandle(ref, () => ({
      openModal: (id: string | number) => {
        setSelectedHotId(id);
        setNewPrice(hots?.find((hot) => hot.id === id)?.price?.toString() || '');
        setIsVisible(true);
      },
    }));

    const handleSave = () => {
      if (!selectedHotId) return;

      const updatedHots = hots?.map((hot) =>
        hot.id === selectedHotId || isCheckedAllContent
          ? { ...hot, price: parseInt(newPrice) || null }
          : hot
      );

      updatedHots && setHots(updatedHots);
      setIsVisible(false);
    };

    return (
      <Modal isVisible={isVisible} close={() => setIsVisible(false)}>
        <p className={styles.title}>Изменить цену</p>
        <div className={styles.input_container}>
          <Input
            additionalClasses={styles.input}
            tip={'Назначьте цену'}
            tipAsPlaceholder={true}
            value={newPrice}
            valueType="number"
            setValue={setNewPrice}
            type={3}
          />
          <span>$</span>
        </div>
        <div className={styles.checkbox_container}>
          <Checkbox
            className={styles.checkbox}
            checked={isCheckedAllContent}
            onClick={() => setIsCheckedAllContent((prev) => !prev)}
          />
          <span className={styles.checkbox_text}>Применить ко всем фото/видео</span>
        </div>
        <div className={styles.buttons_container}>
          <Button type={4} onClick={() => setIsVisible(false)}>
            Отменить
          </Button>
          <Button type={3} onClick={handleSave}>
            Сохранить
          </Button>
        </div>
      </Modal>
    );
  }
);
