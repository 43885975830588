import { useRef, useState } from 'react';
import { useKeyDownOutside } from 'shared/hooks/useKeyDownOutside/useKeyDownOutside';
import { useOutsideClick } from 'shared/hooks/useOutsideClick/useOutsideClick';
import { BUTTONS_ON_KEYBOARD } from 'shared/constans/buttonOnKeyboard/buttonOnKeyboard';
import classNames from 'classnames';

import { GradientSelectPropsI } from './GradientSelect.props';
import styles from './GradientSelect.module.scss';

export const GradientSelect = ({ onClickItem, isError }: GradientSelectPropsI) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const [currentColor, setCurrentColor] = useState('');

  useOutsideClick(selectRef, () => setIsOpen(false), isOpen);
  useKeyDownOutside(selectRef, () => setIsOpen(false), isOpen, BUTTONS_ON_KEYBOARD.Enter);

  const options = [
    {
      id: 1,
      color: 'linear-gradient(to right, rgba(185, 166, 153, 1), rgba(119, 108, 104, 1))',
      classname: 'dusty_rose',
    },
    {
      id: 2,
      color: 'linear-gradient(to right, rgba(185, 166, 153, 1), rgba(123, 110, 10, 1))',
      classname: 'lemon_chiffon',
    },
    {
      id: 3,
      color: 'linear-gradient(to right, rgba(200, 160, 100, 1), rgba(168, 126, 78, 1))',
      classname: 'peach',
    },
    {
      id: 4,
      color: 'linear-gradient(to right, rgba(171, 171, 171, 1), rgba(129, 129, 129, 1))',
      classname: 'silver',
    },
    {
      id: 5,
      color: 'linear-gradient(to right, rgba(227, 146, 170, 1), rgba(171, 67, 79, 1))',
      classname: 'coral',
    },
    {
      id: 6,
      color: 'linear-gradient(to right, rgba(168, 137, 101, 1), rgba(122, 94, 60, 1))',
      classname: 'camel',
    },
    {
      id: 7,
      color: 'linear-gradient(to right, rgba(175, 126, 255, 1), rgba(132, 43, 225, 1))',
      classname: 'purple',
    },
    {
      id: 8,
      color: 'linear-gradient(to right, rgba(223, 180, 204, 1), rgba(156, 106, 137, 1))',
      classname: 'mauve_blush',
    },
    {
      id: 9,
      color: 'linear-gradient(to right, rgba(84, 110, 161, 1), rgba(26, 42, 79, 1))',
      classname: 'midnight_navy',
    },
    {
      id: 10,
      color: 'linear-gradient(to right, rgba(255, 153, 102, 1), rgba(176, 64, 21, 1))',
      classname: 'sunset_flame',
    },
  ];

  const handleClickOpenSelect = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickItem = (id: number, color: string, classname: string) => {
    onClickItem(classname);
    setCurrentColor(color);
    setIsOpen(false);
  };
  return (
    <div ref={selectRef} className={classNames(styles.container, isError && styles.error)}>
      <button onClick={handleClickOpenSelect} className={styles.button}>
        <div className={styles.color_block} style={{ backgroundImage: currentColor }}>
          {!currentColor && <div className={styles.color_text}>Градиент</div>}
        </div>
      </button>
      {isOpen && (
        <ul className={styles.select_list}>
          {options.map(({ id, color, classname }) => (
            <li className={styles.select_item} key={id}>
              <button
                className={styles.item_button}
                onClick={() => handleClickItem(id, color, classname)}
                style={{ backgroundImage: color }}
              ></button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
