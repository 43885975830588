import axios from 'axios';
import type { AxiosError, AxiosResponse } from 'axios';

interface ValidationResponse extends AxiosResponse {
  status: 422;
  data: {
    message: string;
    errors: {
      path: string[];
      message: string;
    }[];
  };
}

export interface ValidationError extends AxiosError {
  response: ValidationResponse;
}

export function isValidationError(error: unknown): error is ValidationError {
  return Boolean(
    axios.isAxiosError(error) &&
      error.response &&
      error.response.status === 422 &&
      typeof error.response.data?.message === 'string' &&
      error.response.data?.message === 'VALIDATION_ERROR' &&
      Array.isArray(error.response.data?.errors)
  );
}
