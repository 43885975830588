import { memo } from 'react';
import { Badge } from 'shared/ui/Badge';
import classNames from 'classnames';

import styles from './QuickFilter.module.scss';
import { QuickFiltersPropsI } from './QuickFilters.props';

export const QuickFilters = memo(
  ({ catalogs, quickFilters, handleClickSetCatalog }: QuickFiltersPropsI) => {
    return (
      <ul className={styles.badges}>
        {catalogs.map(({ id, title }) => (
          <li key={id}>
            <button onClick={() => handleClickSetCatalog(id)}>
              <Badge
                value={title['ru'].toUpperCase()}
                additionalClasses={`${classNames(
                  styles.badge,
                  quickFilters.includes(id) ? styles.active_badge : styles.badge
                )}`}
              />
            </button>
          </li>
        ))}
      </ul>
    );
  }
);
