import { Button } from 'shared/ui/Button';
import { CatalogQuestionnairesWidget } from 'widgets/Catalogs/CatalogQuestionnairesWidget';
import { CatalogSelectsWidget } from 'widgets/Catalogs/CatalogSelectsWidget';
import { CatalogForm } from 'widgets/Catalogs/CatalogForm';

import styles from './CreateCatalogPage.module.scss';
import { useCatalog } from '../../shared/hooks/useCatalog';
export const CreateCatalogPage = () => {
  const { form, handleChangeForm, handleCreateCatalog } = useCatalog({});

  return (
    <div style={{ width: '100%' }}>
      <CatalogForm form={form} handleChangeForm={handleChangeForm} />
      <CatalogSelectsWidget />
      <CatalogQuestionnairesWidget />
      <Button onClick={handleCreateCatalog} type={3} additionalClasses={styles.btn}>
        Опубликовать
      </Button>
    </div>
  );
};
