import { useEffect } from 'react';
import { useSwither } from 'shared/hooks/useSwither';
import classNames from 'classnames';

import styles from './ImagesSwitcher.module.scss';
import { ImagesSwitherProps } from './ImagesSwitcher.props';
import { ImagesList } from './ImageList';
import { Badge } from '../Badge';

export const ImagesSwitcher = ({
  catalogs,
  images,
  badgeClasses,
  height,
  width,
  isHover = true,
  roundButtons = false,
  onImageChange,
}: ImagesSwitherProps) => {
  const {
    currentImageIndex,
    handleButtonClick,
    handleButtonMouseEnter,
    handleTouchMove,
    handleTouchStart,
    setCurrentImageIndex,
  } = useSwither(images);
  useEffect(() => {
    onImageChange && onImageChange(currentImageIndex);
  }, [currentImageIndex, onImageChange]);

  useEffect(() => {
    if (images?.length === currentImageIndex) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  }, [currentImageIndex, images, setCurrentImageIndex]);

  const getRenderContent = () => {
    return (
      <>
        <div className={styles.badge_container}>
          {Array.isArray(catalogs) &&
            catalogs.map(({ title, classname }, index) => (
              <Badge
                key={index}
                additionalClasses={`${styles.badge} ${badgeClasses || ''}`}
                value={title['ru']}
                type={classname}
              />
            ))}
        </div>
        <ImagesList
          images={images}
          currentImageIndex={currentImageIndex}
          width={width}
          height={height}
        />
        {isHover && (
          <div className={styles.enter_coitaner}>
            {images?.map((_, index) => (
              <div
                onMouseEnter={() => handleButtonMouseEnter(index)}
                className={styles.enter_container_item}
                key={index}
              ></div>
            ))}
          </div>
        )}
        {images && images.length > 1 && (
          <div
            className={classNames(
              styles.button_container,
              roundButtons && styles.button_container_round
            )}
          >
            {images?.map((_, index) => (
              <button
                className={classNames(
                  styles.button,
                  index === currentImageIndex && styles.button_active,
                  roundButtons && styles.button_round
                )}
                key={index}
                onClick={(event) => handleButtonClick(event, index)}
              ></button>
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      <div className={styles.image_wrapper}>{getRenderContent()}</div>
    </div>
  );
};
