import { memo, useEffect, useRef, useState } from 'react';
import { Button } from 'shared/ui/Button';
import { DeletePhotoButton } from 'features/questionnaire/DeletePhotoButton';
import { UploadModal } from 'widgets/UploadModal';
import { ReactComponent as EditSVG } from 'shared/assets/icons/edit.svg';
import { ChangePriceModal } from 'widgets/ChangePriceModal/ChangePriceModal';
import { Badge } from 'shared/ui/Badge';
import { v4 as uuidv4 } from 'uuid';

import styles from './Hot.module.scss';
import { ReactComponent as AddIcon } from '../../../shared/assets/icons/addsquare.svg';
import { IMAGES_URL } from '../../../shared/config/urls';
import { HotPropsI } from './Hot.props';
import { FileType } from '../../../shared/types/FileType';
import { QuestionnaireContent } from '../../../entities/questionnaires/types';
import processing from '../../../shared/assets/mock/processing.jpg';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { setIsVisibleUploadModal } from '../../../entities/questionnaires/models';
import { useSocket } from '../../../shared/hooks/useSocket';
import { ChangePriceModalRefI } from '../../ChangePriceModal/ChangePriceModal.props';

export const Hot = memo(({ hots, setHots, isCreateMode }: HotPropsI) => {
  const changePriceModalRef = useRef<ChangePriceModalRefI | null>(null);
  const { isVisibleUploadModal } = useAppSelector((store) => store.questionnaires);
  const dispatch = useAppDispatch();
  const { joinRoom, leaveRoom, onEvent, offEvent } = useSocket();

  useEffect(() => {
    const room = 'admin-video';
    const event = 'video-upload';
    joinRoom(room);

    const handleVideoUpload = (data: {
      correlationId: string;
      full: string;
      preview: string;
      fullBlur: string;
      previewBlur: string;
      type: 'video' | 'image';
    }) => {
      setHots((prev) => {
        if (prev) {
          prev.map((item) => {
            if (item.file.correlationId === data.correlationId) {
              item.file = data;
            }
          });
          return [...prev];
        } else {
          return null;
        }
      });
    };

    onEvent(event, handleVideoUpload);

    return () => {
      offEvent(event, handleVideoUpload);
      leaveRoom(room);
    };
  }, [joinRoom, leaveRoom, onEvent, offEvent]);

  const handleSetFiles = (newFiles: FileType[]) => {
    const content = newFiles.map((file) => ({
      id: uuidv4(),
      type: file.type === 'image' ? 'hot-image' : 'hot-video',
      price: file.price,
      order: hots?.length ? Math.max(...hots.map((item) => item.order || 0)) + 1 : 1,
      file,
    })) as QuestionnaireContent[];
    setHots((prev) => {
      if (prev) {
        return [...prev, ...content];
      } else {
        return content;
      }
    });
  };

  return (
    <ul className={styles.list}>
      <li className={styles.item}>
        <Button onClick={() => dispatch(setIsVisibleUploadModal(true))} type={6}>
          <AddIcon />
          Добавить
        </Button>
      </li>
      {hots &&
        [...hots]
          ?.sort((a, b) => (a.order || 0) - (b.order || 0))
          .map((hot) => (
            <li className={styles.item} key={hot.id}>
              <img
                className={styles.image}
                src={hot.file.preview ? `${IMAGES_URL}${hot.file.preview}` : processing}
                alt="preview"
              />
              <div className={styles.controlls}>
                <button
                  onClick={() => changePriceModalRef.current?.openModal(hot.id)}
                  className={`${styles.button} ${styles.edit}`}
                >
                  <EditSVG />
                </button>
                <div className={styles.button}>
                  <DeletePhotoButton file={hot} setFiles={setHots} isCreateMode={isCreateMode} />
                </div>
              </div>
              {hot.price && <Badge additionalClasses={styles.badge} value={`${hot.price}$`} />}
            </li>
          ))}
      <UploadModal
        isVisible={isVisibleUploadModal}
        close={() => dispatch(setIsVisibleUploadModal(false))}
        handleSetFiles={handleSetFiles}
        dropzoneText="Загрузите фото с устройства или перетащите файлы в это поле"
        title="Добавить новое фото"
        isPutPrice
        accept="image/jpeg, image/png, video/mp4"
      />
      <ChangePriceModal ref={changePriceModalRef} hots={hots} setHots={setHots} />
    </ul>
  );
});
