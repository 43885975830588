import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Tabs } from 'shared/ui/Tabs';
import { Banner } from 'widgets/Questionnaires/Banner';

import styles from './EditQuestionnaire.module.scss';
import { ImageContainer } from '../../widgets/Questionnaires/ImageContainer';
import { ChangeForm } from '../../widgets/Questionnaires/ChangeForm';
import { Photos } from '../../widgets/Questionnaires/Photos';
import { Video } from '../../widgets/Questionnaires/Video';
import { Hot } from '../../widgets/Questionnaires/Hot';
import { useAppDispatch, useAppSelector } from '../../app/store';
import {
  getQuestionnaireContents,
  getQuestionnaireDetails,
} from '../../entities/questionnaires/api';
import { useQuestionnaire } from '../../shared/hooks/useQuestionnaire';
import { generateQueryString } from '../../shared/helpers/generateQueryString';

const EditQuestionnaire = () => {
  const { questionnaireDetails, loadingQuestionnaireDetails, questionnaireContents } =
    useAppSelector((store) => store.questionnaires);
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    photos,
    setPhotos,
    videos,
    setVideos,
    hots,
    setHots,
    avatars,
    setAvatars,
    phone,
    setPhone,
    form,
    handleChangeQuestionnaire,
    handleChangeForm,
  } = useQuestionnaire({ questionnaireDetails: questionnaireDetails || {}, isUpdateMode: true });

  useEffect(() => {
    if (!id) {
      navigate('/error');
    }
  }, [id, navigate]);

  useEffect(() => {
    if (id) {
      dispatch(getQuestionnaireDetails(id));
      const qParams = {
        questionnaireId: id,
      };
      dispatch(getQuestionnaireContents(generateQueryString(qParams)));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (questionnaireContents && questionnaireContents.length > 0) {
      const contentAvatars = questionnaireContents.filter((content) => content.type === 'avatar');
      if (JSON.stringify(avatars) !== JSON.stringify(contentAvatars)) {
        setAvatars && setAvatars(contentAvatars);
      }
      const contentImages = questionnaireContents.filter((content) => content.type === 'image');
      if (JSON.stringify(photos) !== JSON.stringify(contentImages)) {
        setPhotos && setPhotos(contentImages);
      }
      const contentVideos = questionnaireContents.filter((content) => content.type === 'video');
      if (JSON.stringify(videos) !== JSON.stringify(contentVideos)) {
        setVideos && setVideos(contentVideos);
      }
      const contentHots = questionnaireContents.filter(
        (content) => content.type === 'hot-image' || content.type === 'hot-video'
      );
      if (JSON.stringify(hots) !== JSON.stringify(contentHots)) {
        setHots && setHots(contentHots);
      }
    }
  }, [questionnaireContents]);

  if (!id) {
    return null;
  }

  const tabs = [
    {
      content: <Photos photos={photos} setPhotos={setPhotos} isCreateMode={false} />,
      label: 'Фото',
      id: 0,
    },
    {
      content: <Video videos={videos} setVideos={setVideos} isCreateMode={false} />,
      label: 'Видео',
      id: 1,
    },
    {
      content: <Hot hots={hots} setHots={setHots} isCreateMode={false} />,
      label: 'HOT 18+',
      isHot: true,
      id: 2,
    },
  ];

  if (loadingQuestionnaireDetails) {
    return null;
  }

  return (
    <div style={{ width: '100%' }}>
      <Banner
        title="Редактировать анкету"
        buttonText="Сохранить изменения"
        buttonAction={handleChangeQuestionnaire}
      />
      <div className={styles.content}>
        <ImageContainer
          avatars={avatars}
          setAvatars={setAvatars}
          phone={phone}
          setPhone={setPhone}
          isCreateMode={false}
        />
        <div className={styles.info_container}>
          <ChangeForm form={form} handleChangeForm={handleChangeForm} isChangePage />
          <Tabs className={[styles.tabs]} tabs={tabs} />
        </div>
      </div>
      {/* <YouDontSaveModal /> */}
    </div>
  );
};

export default EditQuestionnaire;
