import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { memo, useState } from 'react';
import { DeletePhotoButton } from 'features/questionnaire/DeletePhotoButton';
import { v4 as uuidv4 } from 'uuid';

import styles from './ImageContainer.module.scss';
import { ReactComponent as AddSquareIcon } from '../../../shared/assets/icons/addsquare.svg';
import { ImageContainerPropsI } from './ImagesContainer.props';
import { ImagesSwitcher } from '../../../shared/ui/ImagesSwither';
import { UploadModal } from '../../UploadModal';
import { FileType } from '../../../shared/types/FileType';
import { QuestionnaireContent } from '../../../entities/questionnaires/types';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { setIsVisibleAvatarUploadModal } from '../../../entities/questionnaires/models';

export const ImageContainer = memo(
  ({ image, avatars, setAvatars, phone, setPhone, isCreateMode }: ImageContainerPropsI) => {
    const { isVisibleAvatarUploadModal, errors } = useAppSelector((store) => store.questionnaires);
    const dispatch = useAppDispatch();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handleSetFiles = (newFiles: FileType[]) => {
      const content = newFiles.map((file) => ({
        id: uuidv4(),
        type: 'image',
        file,
      })) as QuestionnaireContent[];
      setAvatars((prev) => {
        if (prev) {
          return [...prev, ...content];
        } else {
          return content;
        }
      });
    };

    return (
      <div className={styles.image_container}>
        <div className={styles.image}>
          {avatars && avatars?.length > 0 ? (
            <ImagesSwitcher
              width={257}
              height={360}
              images={avatars}
              isHover={false}
              roundButtons={true}
              onImageChange={setCurrentImageIndex}
            />
          ) : (
            <div className={styles.placeholder_image}></div>
          )}
          <div className={styles.delete}>
            {avatars && avatars?.length > 0 && (
              <DeletePhotoButton
                file={avatars[currentImageIndex]}
                setFiles={setAvatars}
                isCreateMode={isCreateMode}
              />
            )}
          </div>
        </div>
        <Button
          additionalClasses={styles.add_button}
          onClick={() => dispatch(setIsVisibleAvatarUploadModal(true))}
          type={4}
        >
          <AddSquareIcon />
          <span>Добавить Фото в аватар</span>
        </Button>
        <Input
          setValue={setPhone}
          value={phone}
          tipAsPlaceholder
          additionalClasses={styles.input}
          type={2}
          tip="Телефон (не виден пользователям)"
          isError={!!errors?.phone}
        />
        {errors?.phone && <div className={styles.validError}>{errors?.phone}</div>}
        <UploadModal
          handleSetFiles={handleSetFiles}
          isVisible={isVisibleAvatarUploadModal}
          close={() => dispatch(setIsVisibleAvatarUploadModal(false))}
          dropzoneText="Загрузите фото с устройства или перетащите файлы в это поле"
          title="Добавить новое фото"
          accept="image/jpeg, image/png"
        />
      </div>
    );
  }
);
