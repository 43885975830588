import { useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { BUTTONS_ON_KEYBOARD } from 'shared/constans/buttonOnKeyboard/buttonOnKeyboard';
import { useKeyDownOutside } from 'shared/hooks/useKeyDownOutside/useKeyDownOutside';
import { useOutsideClick } from 'shared/hooks/useOutsideClick/useOutsideClick';
import { getFormatedDate } from 'shared/helpers/dateFunctions';

import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import styles from './Calendar.module.scss';

export function CalendarCustom({ setDate, date, maxDate, title, isError, isIcon = true }: any) {
  const [isVisible, setIsVisible] = useState(false);

  const ref = useRef(null);

  const currentDate = new Date().toISOString().split('T')[0];

  useOutsideClick(ref, () => setIsVisible(false), isVisible);
  useKeyDownOutside(ref, () => setIsVisible(false), isVisible, BUTTONS_ON_KEYBOARD.Enter);

  return (
    <div ref={ref} className={`${styles.container} ${isError && styles.error}`}>
      <button
        className={`${styles.button} ${isError && styles.error_text} ${
          date && getFormatedDate(date) !== 'Invalid Date' && styles.active
        }`}
        onClick={() => setIsVisible(!isVisible)}
      >
        {date && getFormatedDate(date) !== 'Invalid Date' ? getFormatedDate(date) : title}
        {isIcon && <CalendarIcon className={styles.icon} />}
      </button>
      {isVisible && (
        <Calendar
          minDate={new Date(currentDate)}
          className={styles.dropdown}
          onChange={(selectedDate) => {
            setDate(selectedDate);
            setIsVisible(false);
          }}
          value={date}
          maxDate={new Date(maxDate)}
        />
      )}
    </div>
  );
}
