import { Dispatch, SetStateAction } from 'react';
import { Button } from 'shared/ui/Button';

import { ReactComponent as TrashIcon } from '../../../shared/assets/icons/trash.svg';
import { instance } from '../../../shared/config/api/api';
import { QuestionnaireContent } from '../../../entities/questionnaires/types';

interface DeletePhotoButtonPropsI {
  className?: string;
  file: QuestionnaireContent;
  setFiles: Dispatch<SetStateAction<QuestionnaireContent[] | null>>;
  isCreateMode?: boolean;
}

export const DeletePhotoButton = ({
  className,
  file,
  setFiles,
  isCreateMode = true,
}: DeletePhotoButtonPropsI) => {
  const handleDeleteFiles = (file: QuestionnaireContent) => {
    setFiles((prev) => {
      if (prev) {
        return prev.filter(({ id }) => id !== file.id);
      } else {
        return prev;
      }
    });

    if (isCreateMode) {
      instance
        .post('/delete-files', { files: [file.file] })
        .catch((error) => console.error('Ошибка при удалении файла', error));
    }
  };

  return (
    <Button onClick={() => handleDeleteFiles(file)} type={4}>
      <TrashIcon />
    </Button>
  );
};
