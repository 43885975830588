import { useState } from 'react';
import { Select } from 'shared/ui/Select';
import { Input } from 'shared/ui/Input';

import styles from './ChangeForm.module.scss';
import { ChangeFormPropsI } from './ChangeForm.props';
import { CalendarCustom } from '../../../shared/ui/Calendar';

export const ChangeForm = ({ form, handleChangeForm, isChangePage = false }: ChangeFormPropsI) => {
  const [activeLang, setActiveLang] = useState('Ru');

  const languages = [
    { id: 'Ru', label: 'Ru' },
    { id: 'En', label: 'En' },
    { id: 'De', label: 'De' },
  ];

  const langKeys = [`firstName${activeLang}`, `description${activeLang}`];
  const firstRowFormKeys = Object.keys(form).slice(6, 8);
  const secondRowFormKeys = Object.keys(form).slice(8, 11);
  const thirdRowFormKeys = Object.keys(form).slice(11, 15);
  const fourRowFormKeys = Object.keys(form).slice(15, 19);

  return (
    <div className={styles.container}>
      <div className={styles.languageTabs}>
        {languages.map((lang) => (
          <button
            key={lang.id}
            className={`${styles.tabButton} ${activeLang === lang.id ? styles.activeTab : ''}`}
            onClick={() => setActiveLang(lang.id)}
          >
            {lang.label}
          </button>
        ))}
      </div>
      <div className={styles.divider}></div>
      <div className={styles.language_content}>
        {langKeys.map((key) =>
          form[key].type === 'input' ? (
            <div key={form[key].id}>
              <Input
                type={2}
                value={form[key].value || ''}
                tip={form[key].placeholder}
                tipAsPlaceholder={true}
                setValue={(val) => handleChangeForm(key, val)}
                isError={!!form[key].errorText}
              />
              {form[key].errorText && (
                <div className={styles.validError}>{form[key].errorText}</div>
              )}
            </div>
          ) : (
            <div key={form[key].id}>
              <Input
                type={2}
                value={form[key].value || ''}
                tip={form[key].placeholder}
                tipAsPlaceholder={true}
                setValue={(val) => handleChangeForm(key, val)}
                multilines
                additionalClasses={styles.add_top_margin}
                isError={!!form[key].errorText}
              />
              {form[key].errorText && (
                <div className={styles.validError}>{form[key].errorText}</div>
              )}
            </div>
          )
        )}
      </div>
      <div className={styles.divider}></div>
      <div className={styles.two_items_content}>
        {firstRowFormKeys.map((key) => (
          <div key={form[key].id}>
            <Select
              className={styles.select}
              tip={form[key].placeholder}
              v={2}
              options={form[key].selectOptions || []}
              tipAsPlaceholder={true}
              onClickItem={form[key].onClickItem || (() => {})}
              currentOption={form[key].currentOption || ''}
              status={!!form[key].errorText ? 'error' : ''}
            />
            {form[key].errorText && <div className={styles.validError}>{form[key].errorText}</div>}
          </div>
        ))}
      </div>
      <div className={styles.three_items_content}>
        {secondRowFormKeys.map((key) => (
          <div key={form[key].id}>
            <Select
              className={styles.select}
              tip={form[key].placeholder}
              v={2}
              options={form[key].selectOptions || []}
              tipAsPlaceholder={true}
              onClickItem={form[key].onClickItem || (() => {})}
              currentOption={form[key].currentOption || ''}
              status={!!form[key].errorText ? 'error' : ''}
            />
            {form[key].errorText && <div className={styles.validError}>{form[key].errorText}</div>}
          </div>
        ))}
      </div>
      <div className={styles.four_items_content}>
        {thirdRowFormKeys.map((key) => (
          <div key={form[key].id}>
            <Input
              type={2}
              value={form[key].value || ''}
              tip={form[key].placeholder}
              tipAsPlaceholder={true}
              setValue={(val) => handleChangeForm(key, val)}
              isError={!!form[key].errorText}
            />
            {form[key].errorText && <div className={styles.validError}>{form[key].errorText}</div>}
          </div>
        ))}
      </div>
      <div className={styles.bottom_container}>
        {fourRowFormKeys.map((key) =>
          form[key].type === 'select' ? (
            <div key={form[key].id}>
              {' '}
              <Select
                className={styles.select}
                tip={form[key].placeholder}
                v={2}
                tipAsPlaceholder={true}
                options={form[key].selectOptions || []}
                onClickItem={form[key].onClickItem || (() => {})}
                currentOption={form[key].currentOption || ''}
                status={!!form[key].errorText ? 'error' : ''}
              />
              {form[key].errorText && (
                <div className={styles.validError}>{form[key].errorText}</div>
              )}
            </div>
          ) : form[key].type === 'calendar' ? (
            <div key={form[key].id}>
              <CalendarCustom
                title={form[key].placeholder}
                date={form[key].value}
                setDate={(date: any) => handleChangeForm(key, date)}
                isError={!!form[key].errorText}
              />
              {form[key].errorText && (
                <div className={styles.validError}>{form[key].errorText}</div>
              )}
            </div>
          ) : (
            <div key={form[key].id}>
              <Input
                type={2}
                value={form[key].value || ''}
                tip={form[key].placeholder}
                tipAsPlaceholder={true}
                setValue={(val) => handleChangeForm(key, val)}
                isError={!!form[key].errorText}
              />
              {form[key].errorText && (
                <div className={styles.validError}>{form[key].errorText}</div>
              )}
            </div>
          )
        )}
      </div>
      {/* <YouDontSaveModal /> */}
    </div>
  );
};
