import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from 'shared/config/api/api';

import {
  QuestionnairesResponse,
  QuestionnaireDetailsResponse,
  QuestionnaireContentResponse,
  CatalogsResponse,
  CountriesResponse,
  CitiesResponse,
  TypesResponse,
  FormatsResponse,
  CreateQuestionnaireRequestDataI,
} from '../types';
import { isValidationError } from '../../../shared/types/Error';

export const getAllQuestionnaires = createAsyncThunk(
  'questionnaires/all',
  async (qParamsParsedString: string) => {
    const response = await instance.get<QuestionnairesResponse>(
      `/questionnaires${qParamsParsedString}`
    );
    return response.data;
  }
);

export const getQuestionnaireDetails = createAsyncThunk(
  'questionnaires/id',
  async (id: string | string[]) => {
    const { data } = await instance.get<QuestionnaireDetailsResponse>(
      `/questionnaires-by-id/${id}`
    );
    return data.questionnaire;
  }
);

export const getQuestionnaireContents = createAsyncThunk(
  'questionnaires/content',
  async (qParamsParsedString: string) => {
    const { data } = await instance.get<QuestionnaireContentResponse>(
      `/questionnaire-contents${qParamsParsedString}`
    );
    return data;
  }
);

export const getCatalogs = createAsyncThunk('/catalog-names', async () => {
  const { data } = await instance.get<CatalogsResponse>('/catalog-names');
  return data;
});

export const getCountries = createAsyncThunk('/countries', async () => {
  const response = await instance.get<CountriesResponse>('/questionnaires/search?countries=true');
  return response.data;
});

export const getCities = createAsyncThunk(
  '/cities',
  async ({ countryId }: { countryId: number }) => {
    const country = countryId ? `&countryId=${countryId}` : '';
    const response = await instance.get<CitiesResponse>(
      `/questionnaires/search?${country}&cities=true`
    );
    return response.data;
  }
);

export const fetchTypes = createAsyncThunk('questionnaires/types', async () => {
  const { data } = await instance.get<TypesResponse>('/questionnaires/types');
  return data;
});

export const fetchFormats = createAsyncThunk('questionnaires/formats', async () => {
  const { data } = await instance.get<FormatsResponse>('/questionnaires/formats');
  return data;
});

export const createQuestionnaire = createAsyncThunk(
  'questionnaires/create',
  async (questionnaireData: CreateQuestionnaireRequestDataI, { rejectWithValue }) => {
    try {
      const response = await instance.post('/questionnaires', questionnaireData);
      return response.data.questionnaire;
    } catch (error: any) {
      if (isValidationError(error)) {
        const validationErrors: Record<string, string> = error.response.data.errors.reduce(
          (acc: Record<string, string>, err: any) => {
            const fieldName = err.path?.[0];
            if (fieldName) {
              acc[fieldName] = err.message;
            }
            return acc;
          },
          {}
        );
        return rejectWithValue(validationErrors);
      }
      throw error;
    }
  }
);

export const updateQuestionnaire = createAsyncThunk(
  'questionnaires/update',
  async (questionnaireData: CreateQuestionnaireRequestDataI, { rejectWithValue }) => {
    const { id, ...rest } = questionnaireData;
    try {
      const response = await instance.patch(`/questionnaires/${id}`, rest);
      return response.data.questionnaire;
    } catch (error: any) {
      if (isValidationError(error)) {
        const validationErrors: Record<string, string> = error.response.data.errors.reduce(
          (acc: Record<string, string>, err: any) => {
            const fieldName = err.path?.[0];
            if (fieldName) {
              acc[fieldName] = err.message;
            }
            return acc;
          },
          {}
        );
        return rejectWithValue(validationErrors);
      }
      throw error;
    }
  }
);

export const deleteQuestionnaire = createAsyncThunk('questionnaires/delete', async (id: number) => {
  const response = await instance.delete(`/questionnaires/${id}`);
  return response.data;
});
