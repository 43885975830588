import styles from './ErrorPage.module.scss';

const ErrorPage = (): JSX.Element => {
  return (
    <div className={styles.errorPage}>
      <h1>Что-то пошло не так</h1>
      <p>Мы не смогли обработать ваш запрос. Попробуйте позже или обратитесь в поддержку.</p>
    </div>
  );
};

export default ErrorPage;
