import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CalendarCustom } from 'shared/ui/Calendar';
import { Input } from 'shared/ui/Input';

import styles from './CatalogForm.module.scss';
import { ReactComponent as BackButtonIcon } from '../../../shared/assets/icons/arrowsquareleft.svg';
import { GradientSelect } from '../GradientSelect';
import { CatalogFormPropsI } from './CatalogForm.props';

export const CatalogForm = ({ form, handleChangeForm }: CatalogFormPropsI) => {
  const navigate = useNavigate();
  const [activeLang, setActiveLang] = useState('Ru');

  const languages = [
    { id: 'Ru', label: 'Ru' },
    { id: 'En', label: 'En' },
    { id: 'De', label: 'De' },
  ];
  console.log('form!!!', form);
  return (
    <div className={styles.container}>
      <button onClick={() => navigate(-1)} className={styles.back_button}>
        <BackButtonIcon />
      </button>
      <div className={styles.content}>
        <div className={styles.title_row}>
          <p className={styles.title}>Создание каталога</p>
          <div className={styles.languageTabs}>
            {languages.map((lang) => (
              <button
                key={lang.id}
                className={`${styles.tabButton} ${activeLang === lang.id ? styles.activeTab : ''}`}
                onClick={() => setActiveLang(lang.id)}
              >
                {lang.label}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.first_row}>
          <div>
            <Input
              type={2}
              value={form[`title${activeLang}`].value || ''}
              tip={form[`title${activeLang}`].placeholder}
              tipAsPlaceholder={true}
              setValue={(val) => handleChangeForm(`title${activeLang}`, val)}
              isError={!!form[`title${activeLang}`].errorText}
            />
            {form[`title${activeLang}`].errorText && (
              <span className={styles.validError}>{form[`title${activeLang}`].errorText}</span>
            )}
          </div>
          <div>
            <Input
              type={2}
              value={form[`subtitle${activeLang}`].value || ''}
              tip={form[`subtitle${activeLang}`].placeholder}
              tipAsPlaceholder={true}
              setValue={(val) => handleChangeForm(`subtitle${activeLang}`, val)}
              isError={!!form[`subtitle${activeLang}`].errorText}
            />
            {form[`subtitle${activeLang}`].errorText && (
              <span className={styles.validError}>{form[`subtitle${activeLang}`].errorText}</span>
            )}
          </div>
        </div>
        <div className={styles.second_row}>
          <div>
            <Input
              type={2}
              value={form[`tag${activeLang}`].value || ''}
              tip={form[`tag${activeLang}`].placeholder}
              tipAsPlaceholder={true}
              setValue={(val) => handleChangeForm(`tag${activeLang}`, val)}
              isError={!!form[`tag${activeLang}`].errorText}
            />
            {form[`tag${activeLang}`].errorText && (
              <span className={styles.validError}>{form[`tag${activeLang}`].errorText}</span>
            )}
          </div>
          <div>
            <GradientSelect
              onClickItem={(val) => handleChangeForm('classname', val)}
              isError={!!form['classname'].errorText}
            />
            {form['classname'].errorText && (
              <span className={styles.validError}>{form['classname'].errorText}</span>
            )}
          </div>
          <div>
            <CalendarCustom
              title={form['plannedPublication'].placeholder}
              date={form['plannedPublication'].value}
              setDate={(date: any) => handleChangeForm('plannedPublication', date)}
              isError={!!form['plannedPublication'].errorText}
            />
            {form['plannedPublication'].errorText && (
              <span className={styles.validError}>{form['plannedPublication'].errorText}</span>
            )}
          </div>
        </div>
        <div className={styles.third_row}>
          <div>
            <Input
              type={2}
              value={form['price'].value || ''}
              tip={form['price'].placeholder}
              tipAsPlaceholder={true}
              setValue={(val) => handleChangeForm('price', val)}
              isError={!!form['price'].errorText}
            />
            {form['price'].errorText && (
              <span className={styles.validError}>{form['price'].errorText}</span>
            )}
          </div>
          <div>
            <Input
              type={2}
              value={form['priceDemo'].value || ''}
              tip={form['priceDemo'].placeholder}
              tipAsPlaceholder={true}
              setValue={(val) => handleChangeForm('priceDemo', val)}
              isError={!!form['priceDemo'].errorText}
            />
            {form['priceDemo'].errorText && (
              <span className={styles.validError}>{form['priceDemo'].errorText}</span>
            )}
          </div>
          <div>
            <Input
              type={2}
              value={form['priceSubscription'].value || ''}
              tip={form['priceSubscription'].placeholder}
              tipAsPlaceholder={true}
              setValue={(val) => handleChangeForm('priceSubscription', val)}
              isError={!!form['priceSubscription'].errorText}
            />
            {form['priceSubscription'].errorText && (
              <span className={styles.validError}>{form['priceSubscription'].errorText}</span>
            )}
          </div>
        </div>
        <Input
          type={2}
          value={form[`description${activeLang}`].value || ''}
          tip={form[`description${activeLang}`].placeholder}
          tipAsPlaceholder={true}
          setValue={(val) => handleChangeForm(`description${activeLang}`, val)}
          multilines
          additionalClasses={styles.add_top_margin}
          isError={!!form[`description${activeLang}`].errorText}
        />
        {form[`description${activeLang}`].errorText && (
          <div className={styles.validError}>{form[`description${activeLang}`].errorText}</div>
        )}
      </div>
    </div>
  );
};
