import { Button } from 'shared/ui/Button';
import { ImagesSwitcher } from 'shared/ui/ImagesSwither';
import { Questionnaire } from 'entities/questionnaires/types';
import { ReactComponent as Edit } from 'shared/assets/icons/edit-sm.svg';
import { ReactComponent as Switch } from 'shared/assets/icons/switch.svg';
import { ReactComponent as Trash } from 'shared/assets/icons/trash2.svg';
import ROUTES from 'shared/constans/routes';
import { memo } from 'react';

import styles from './QuestionnairesItem.module.scss';
import { useAppDispatch } from '../../../../app/store';
import { deleteQuestionnaire } from '../../api';

export const QuestionnairyItem = memo(
  ({ id, avatars, firstName, age, catalogs }: Questionnaire) => {
    const dispatch = useAppDispatch();

    return (
      <div className={styles.item}>
        <ImagesSwitcher catalogs={catalogs} images={avatars} />
        <div className={styles.info_container}>
          <span className={styles.info}>
            {firstName?.ru}, {age}
          </span>
          <div className={styles.bussines_button_container}>
            <Button
              additionalClasses={styles.bussines_button}
              tag="link"
              href={`${ROUTES.EDIT_QUESTIONNAIRE}/${id}`}
              type={4}
            >
              <span className="flex align-center gap5">
                <Edit /> Редактировать
              </span>
            </Button>
            <Button onClick={() => {}} additionalClasses={styles.bussines_button} type={4}>
              <span className="flex align-center gap5">
                <Switch />
              </span>
            </Button>
            <Button
              onClick={() => {
                id && dispatch(deleteQuestionnaire(id));
              }}
              additionalClasses={styles.bussines_button}
              type={4}
            >
              <span className="flex align-center gap5">
                <Trash />
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
