import { Banner } from 'widgets/Questionnaires/Banner';
import { ImageContainer } from 'widgets/Questionnaires/ImageContainer';
import { ChangeForm } from 'widgets/Questionnaires/ChangeForm';
import { Tabs } from 'shared/ui/Tabs';
import { Photos } from 'widgets/Questionnaires/Photos';
import { Video } from 'widgets/Questionnaires/Video';
import { Hot } from 'widgets/Questionnaires/Hot';

import styles from './CreateQuestionnaire.module.scss';
import { useQuestionnaire } from '../../shared/hooks/useQuestionnaire';

const CreateQuestionnaire = () => {
  const {
    photos,
    setPhotos,
    videos,
    setVideos,
    hots,
    setHots,
    avatars,
    setAvatars,
    phone,
    setPhone,
    form,
    handleChangeForm,
    handleCreateQuestionnaire,
  } = useQuestionnaire({});

  const tabs = [
    {
      content: <Photos photos={photos} setPhotos={setPhotos} />,
      label: 'Фото',
      id: 0,
    },
    {
      content: <Video videos={videos} setVideos={setVideos} />,
      label: 'Видео',
      id: 1,
    },
    {
      content: <Hot hots={hots} setHots={setHots} />,
      label: 'HOT 18+',
      isHot: true,
      id: 2,
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <Banner
        title="Создать анкету"
        buttonText="Опубликовать"
        buttonAction={handleCreateQuestionnaire}
      />
      <div className={styles.content}>
        <ImageContainer
          avatars={avatars}
          setAvatars={setAvatars}
          phone={phone}
          setPhone={setPhone}
        />
        <div className={styles.info_container}>
          <ChangeForm form={form} handleChangeForm={handleChangeForm} />
          <Tabs className={[styles.tabs]} tabs={tabs} />
        </div>
      </div>
    </div>
  );
};

export default CreateQuestionnaire;
