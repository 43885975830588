import classNames from 'classnames';

import styles from './Pagination.module.scss';
import { PaginationPropsT } from './Pagination.props';

const siblingCount = 1;

const range = (start: number, end: number) => {
  let length = end - start + 1;

  return Array.from({ length }, (_, i) => i + start);
};

export const Pagination = ({
  currentPage,
  nextPage,
  previousPage,
  totalPages,
  changePage,
  disabled,
  additionalClasses,
}: PaginationPropsT) => {
  const paginationRange = () => {
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPages) {
      return range(1, totalPages);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [
        ...leftRange,
        <span className={styles.multipoint} key="rightDots">
          ...
        </span>,
        totalPages,
      ];
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(totalPages - rightItemCount + 1, totalPages);
      return [
        firstPageIndex,
        <span className={styles.multipoint} key="leftDots">
          ...
        </span>,
        ,
        ...rightRange,
      ];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [
        firstPageIndex,
        <span className={styles.multipoint} key="rightDots">
          ...
        </span>,
        ...middleRange,
        <span className={styles.multipoint} key="leftDots">
          ...
        </span>,
        lastPageIndex,
      ];
    }
  };

  return (
    <div className={`${styles.pagination} ${additionalClasses || ''}`}>
      <button
        disabled={currentPage === 1}
        className={`${styles.button} ${styles.active_button}`}
        onClick={() => {
          if (previousPage) {
            changePage(previousPage);
          }
        }}
      >
        Предыдущая
      </button>

      <ul className={styles.list}>
        {paginationRange()?.map((page) => {
          if (typeof page === 'number') {
            return (
              <li className={styles.item} key={page}>
                <button
                  onClick={() => changePage(page)}
                  className={classNames(
                    styles.button,
                    { [styles.active_button]: page === currentPage },
                    []
                  )}
                  disabled={disabled}
                >
                  {page}
                </button>
              </li>
            );
          } else {
            return (
              <li className={styles.item} key={page?.key}>
                <button className={styles.button} disabled={true}>
                  {page}
                </button>
              </li>
            );
          }
        })}
      </ul>

      <button
        disabled={!nextPage}
        className={`${styles.button} ${styles.active_button}`}
        onClick={() => {
          if (nextPage) {
            changePage(nextPage);
          }
        }}
      >
        Следующая
      </button>
    </div>
  );
};
