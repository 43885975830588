import { memo, useState } from 'react';
import { Button } from 'shared/ui/Button';
import { DeletePhotoButton } from 'features/questionnaire/DeletePhotoButton';
import { UploadModal } from 'widgets/UploadModal';
import { v4 as uuidv4 } from 'uuid';

import styles from './Photos.module.scss';
import { ReactComponent as AddIcon } from '../../../shared/assets/icons/addsquare.svg';
import { IMAGES_URL } from '../../../shared/config/urls';
import { PhotosPropsI } from './Photos.props';
import { FileType } from '../../../shared/types/FileType';
import { QuestionnaireContent } from '../../../entities/questionnaires/types';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { setIsVisibleUploadModal } from '../../../entities/questionnaires/models';

export const Photos = memo(({ photos, setPhotos, isCreateMode }: PhotosPropsI) => {
  const { isVisibleUploadModal } = useAppSelector((store) => store.questionnaires);
  const dispatch = useAppDispatch();

  const handleSetFiles = (newFiles: FileType[]) => {
    const content = newFiles.map((file) => ({
      id: uuidv4(),
      type: 'image',
      order: photos?.length ? Math.max(...photos.map((item) => item.order || 0)) + 1 : 1,
      file,
    })) as QuestionnaireContent[];
    setPhotos((prev) => {
      if (prev) {
        return [...prev, ...content];
      } else {
        return content;
      }
    });
  };

  return (
    <ul className={styles.list}>
      <li className={styles.button_container}>
        <Button
          additionalClasses={styles.item}
          onClick={() => dispatch(setIsVisibleUploadModal(true))}
          type={6}
        >
          <AddIcon />
          Добавить
        </Button>
      </li>
      {photos &&
        [...photos]
          .sort((a, b) => (a.order || 0) - (b.order || 0))
          .map((photo) => (
            <li className={styles.item} key={photo.id}>
              <img
                className={styles.image}
                src={`${IMAGES_URL}${photo.file.preview}`}
                alt="preview"
              />
              <div className={styles.delete}>
                <DeletePhotoButton file={photo} setFiles={setPhotos} isCreateMode={isCreateMode} />
              </div>
            </li>
          ))}
      <UploadModal
        handleSetFiles={handleSetFiles}
        isVisible={isVisibleUploadModal}
        close={() => dispatch(setIsVisibleUploadModal(false))}
        dropzoneText="Загрузите фото с устройства или перетащите файлы в это поле"
        title="Добавить новое фото"
        accept="image/jpeg, image/png"
      />
    </ul>
  );
});
